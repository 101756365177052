import React, { useMemo } from 'react'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { useGetGamesWithFilterQuery } from '../../../../api/core'
import userService from '../../../../services/UserService'
import { GameFilter } from '../../../../types/GameFilter'
import { useUserIsAdminCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { GameSearchDialogConfig } from '../../../game-search/types/GameSearchDialogConfig'
import { Game } from '../../../game/types/Game'
import { GameWithMarketIso } from '../GameSearchDialog/GameSearchDialog'
import { GameSearchResultsTable } from '../GameSearchResultsTable/GameSearchResultsTable'
import './GameSearchDialogMyPortfolioTab.scss'

interface Props {
  marketIso: string
  gameSearchDialogConfig: GameSearchDialogConfig
  selectedGames: { [gameId: string]: Game }
  gamesWithMarketIso?: GameWithMarketIso
  enableMarketSelector?: boolean
  onGameSelect: (game: Game) => void
  onGameDeselect: (game: Game) => void
}

const gameInclude = [
  'appId',
  'name',
  'artist',
  'icon',
  'icons',
  'gpsPerMarket',
  'visibility',
  'genreId',
  'internal',
  'owner',
  'archive',
  'stageId',
  'conventionalSubgenreId',
  'conventionalSubgenre',
]

const filter: GameFilter[] = [GameFilter.Own, GameFilter.Analyzed]

const GameSearchDialogMyPortfolioTab: React.FC<Props> = ({
  marketIso,
  gameSearchDialogConfig,
  selectedGames,
  onGameSelect,
  onGameDeselect,
  gamesWithMarketIso,

  enableMarketSelector,
}) => {
  const userLanguage = useCurrentUserLanguage()
  const { data: gamesData, isLoading } = useGetGamesWithFilterQuery({
    filter: filter,
    include: gameInclude,
    marketIso: marketIso,
    userLanguage,
  })

  const { data: currentUser } = useGetUserProfileQuery()
  const isAdmin = useUserIsAdminCheck()

  const games = useMemo(() => {
    if (!gamesData || !currentUser) return []

    return gamesData?.filter((game) => {
      return game.getPowerScoreForMarket(marketIso) > 0 && userService.hasAccessToGame(currentUser, game, isAdmin)
    })
  }, [currentUser, gamesData, isAdmin, marketIso])

  return (
    <div className="GameSearchDialogSoftLaunchTab">
      <GameSearchResultsTable
        games={games || []}
        marketIso={marketIso}
        isLoading={isLoading}
        hideRankData={true}
        onGameSelect={onGameSelect}
        onGameDeselect={onGameDeselect}
        gameSearchDialogConfig={gameSearchDialogConfig}
        selectedGames={selectedGames}
        enableMarketSelector={enableMarketSelector}
        gamesWithMarketIso={gamesWithMarketIso}
      />
    </div>
  )
}

export default GameSearchDialogMyPortfolioTab
