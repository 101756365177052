import classNames from 'classnames'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, To, useLocation } from 'react-router-dom'

import { Download, GridView, ViewCarousel } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material'

import { Screenshot } from '../../api/core'
import { EventDialogTab } from '../../features/live-events/hooks/useEventDialogTabs'
import { useLiveEventModal } from '../../features/live-events/hooks/useLiveEventModal'
import { theme } from '../../themes/theme'
import { FullscreenToggler } from '../FullscreenToggler/FullscreenToggler'
import { ImageCarouselModal } from '../ImageCarouselModal/ImageCarouselModal'
import { LiveEventModalLinkProps } from '../LiveEventModalLink/LiveEventModalLink'
import MultiSlider from '../MultiSlider/MultiSlider'
import './GalleryCarousel.scss'

// Define custom types for various props used in the component
export type GalleryCarouselImageListingType = 'carousel' | 'thumbnails'
export type GalleryCarouselImagePreviewMode = 'default' | 'fullscreen'
export type GalleryCarouselThumbnailGridSize = 6 | 4 | 3
export type GalleryCarouselCarouselImageCount = 1 | 2 | 3

interface GalleryCarouselProps {
  screenshots: string[] | Screenshot[] // Array of either strings (URLs) or Screenshot objects
  screenshotIndex?: number // Index of the screenshot to display
  imageListingType?: GalleryCarouselImageListingType // Determines whether images are displayed in carousel or thumbnails
  imagePreviewMode?: GalleryCarouselImagePreviewMode // Determines whether images are shown in default or fullscreen mode
  thumbnailGridSize?: GalleryCarouselThumbnailGridSize // Grid size for thumbnails
  carouselImageCount?: GalleryCarouselCarouselImageCount // Number of images to display in the carousel
  padding?: number // Padding for the carousel
  hasNewsFeedLimitedAccess?: boolean // Flag to determine if the user has limited access to the news feed
  showDownloadAllButton?: boolean // Flag to determine if the download all button should be displayed
  liveEventModalLinkProps?: LiveEventModalLinkProps
  onImageListingTypeChange?: (newType: GalleryCarouselImageListingType) => void // Callback for when image listing type changes
  onImagePreviewModeChange?: (newMode: GalleryCarouselImagePreviewMode) => void // Callback for when image preview mode changes
}

// GalleryCarousel Component
const GalleryCarousel: React.FC<GalleryCarouselProps> = ({
  screenshots,
  screenshotIndex = 0,
  imageListingType = 'carousel',
  imagePreviewMode = 'default',
  thumbnailGridSize = 3,
  carouselImageCount = 3,
  padding = 20,
  hasNewsFeedLimitedAccess = false,
  showDownloadAllButton = true,
  liveEventModalLinkProps,
  onImageListingTypeChange,
  onImagePreviewModeChange,
}) => {
  const classes = classNames('GalleryCarousel')
  const { t } = useTranslation()
  const location = useLocation()
  const isFullscreenAvailable = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const isStringType = Array.isArray(screenshots) && screenshots[0] && typeof screenshots[0] === 'string' // Determine if screenshots are strings (URLs)
  const [imageCarouselModalOpen, setImageCarouselModalOpen] = useState<boolean>(false) // State to manage the modal visibility
  const [selectedScreenshotIndex, setSelectedScreenshotIndex] = useState<number>(screenshotIndex) // State to track the selected screenshot index
  const isInfinite = screenshots.length > carouselImageCount
  const { showModal: showEventModal } = useLiveEventModal()

  // Function to handle changes in image listing type
  const handleImageListingTypeChange = (newType: GalleryCarouselImageListingType) => {
    if (imageListingType !== newType) {
      onImageListingTypeChange?.(newType)
    }
  }

  // Function to handle changes in image preview mode (fullscreen vs default)
  const handleImagePreviewModeChange = (newMode: GalleryCarouselImagePreviewMode) => {
    if (imagePreviewMode !== newMode) {
      onImagePreviewModeChange?.(newMode)
    }
  }

  // Function to download all images
  const handleDownloadAll = async () => {
    const zip = new JSZip()
    const folder = zip.folder('screenshots') // Create a folder inside the zip

    const fetchImage = (url: string) => {
      return fetch(url).then((res) => res.blob())
    }

    // Add each screenshot to the zip
    await Promise.all(
      screenshots.map(async (screenshot, index) => {
        const imageUrl = typeof screenshot === 'string' ? screenshot : (screenshot as Screenshot).url
        const blob = await fetchImage(imageUrl)
        const filename = `screenshot-${index + 1}.jpg`
        folder?.file(filename, blob)
      })
    )

    // Generate the zip and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'screenshots.zip')
    })
  }

  // Function to navigate to the next or previous screenshot in the carousel

  return (
    <div className={classes}>
      <Grid container alignItems="center" justifyContent="center" mb={2} columnSpacing={1}>
        {onImagePreviewModeChange && isFullscreenAvailable && (
          <Grid item>
            {/* Fullscreen toggle button */}
            <FullscreenToggler
              active={imagePreviewMode === 'fullscreen'}
              onClick={() => handleImagePreviewModeChange(imagePreviewMode === 'default' ? 'fullscreen' : 'default')}
              enterTooltip={<Trans i18nKey="live-events:event_dialog_screenshot_maximize" />}
              exitTooltip={<Trans i18nKey="live-events:event_dialog_screenshot_minimize" />}
            />
          </Grid>
        )}

        {showDownloadAllButton && !hasNewsFeedLimitedAccess && (
          <Grid item>
            {/* Download all button */}
            <Tooltip title={t('common:download_all')} arrow>
              <IconButton size="small" color="secondary" className="DownloadAllButton" onClick={handleDownloadAll}>
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        {onImagePreviewModeChange || (showDownloadAllButton && !hasNewsFeedLimitedAccess) ? (
          <Grid item alignSelf="stretch">
            <Divider orientation="vertical" />
          </Grid>
        ) : null}

        <Grid item>
          {/* Button group to toggle between carousel and thumbnails view */}
          <ButtonGroup className="ButtonGroup" variant="outlined" size="small">
            {['carousel', 'thumbnails'].map((type) => {
              return (
                <Button
                  key={`image-list-button__${type}`}
                  variant={imageListingType === type ? 'contained' : 'outlined'}
                  color={imageListingType === type ? 'secondary' : 'info'}
                  onClick={() => handleImageListingTypeChange(type as GalleryCarouselImageListingType)}
                  title={type === 'carousel' ? t('common:carousel_view') : t('common:gallery_view')}
                >
                  {type === 'carousel' ? <ViewCarousel /> : <GridView />}
                </Button>
              )
            })}
          </ButtonGroup>
        </Grid>
      </Grid>

      {/* Carousel view */}
      {imageListingType === 'carousel' && screenshots.length > 0 && (
        <Box mb={3} position={'relative'}>
          {isStringType ? (
            <MultiSlider infinite={isInfinite} count={carouselImageCount} showIndex={true} padding={padding} screenshotIndex={screenshotIndex}>
              {screenshots.map((screenshot, i) => {
                return (
                  <img
                    key={i}
                    alt="Screenshot"
                    src={screenshot as string}
                    onClick={() => {
                      if (hasNewsFeedLimitedAccess) return

                      if (liveEventModalLinkProps) {
                        showEventModal({ ...liveEventModalLinkProps, tab: EventDialogTab.Description, screenshotIndex: i })
                        return
                      }

                      setImageCarouselModalOpen(true)
                      setSelectedScreenshotIndex(i)
                    }}
                    className={classNames('Screenshot', { 'Screenshot--no-hover': hasNewsFeedLimitedAccess })}
                  />
                )
              })}
            </MultiSlider>
          ) : (
            <MultiSlider infinite={isInfinite} count={carouselImageCount} showIndex={true} padding={padding} screenshotIndex={screenshotIndex}>
              {screenshots.map((screenshot, i) => {
                const linkTo: To = {
                  ...location,
                  pathname: `${location.pathname}/screenshots/${screenshots.map((ss) => (ss as Screenshot).screenshotId).join(',')}/${
                    (screenshot as Screenshot).screenshotId
                  }`,
                }
                return hasNewsFeedLimitedAccess ? (
                  <LazyLoadImage key={i} alt="Screenshot" src={(screenshot as Screenshot)?.url} effect="blur" />
                ) : (
                  <Link key={i} to={linkTo}>
                    <LazyLoadImage alt="Screenshot" src={(screenshot as Screenshot)?.url} effect="blur" />
                  </Link>
                )
              })}
            </MultiSlider>
          )}
        </Box>
      )}

      {/* Gallery view aka thumbnails */}
      {imageListingType === 'thumbnails' && screenshots.length > 0 && (
        <Box mb={10} position={'relative'}>
          <Grid container wrap="wrap" justifyContent={'center'} spacing={2}>
            {screenshots.map((screenshot, index) => {
              return (
                <Grid key={`thumbnail-${index}`} item xs={thumbnailGridSize}>
                  {isStringType ? (
                    <img
                      className={classNames('Thumbnail', { 'Thumbnail--no-hover': hasNewsFeedLimitedAccess })}
                      alt="Thumbnail"
                      src={screenshot as string}
                      onClick={() => {
                        if (hasNewsFeedLimitedAccess) return

                        if (liveEventModalLinkProps) {
                          showEventModal({ ...liveEventModalLinkProps, tab: EventDialogTab.Description, screenshotIndex: index })
                          return
                        }

                        setImageCarouselModalOpen(true)
                        setSelectedScreenshotIndex(index)
                      }}
                    />
                  ) : (
                    (() => {
                      if (hasNewsFeedLimitedAccess)
                        return <img className="Thumbnail" alt="Thumbnail Thumbnail--no-hover" src={(screenshot as Screenshot)?.url} />

                      const linkTo = {
                        ...location,
                        pathname: `${location.pathname}/screenshots/${screenshots.map((ss) => (ss as Screenshot).screenshotId).join(',')}/${
                          (screenshot as Screenshot).screenshotId
                        }`,
                      }

                      return (
                        <Link to={linkTo}>
                          <img className="Thumbnail" alt="Thumbnail" src={(screenshot as Screenshot)?.url} />
                        </Link>
                      )
                    })()
                  )}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}

      {/* Modal for image carousel in full-screen */}
      {imageCarouselModalOpen && isStringType && (
        <ImageCarouselModal
          imageUrls={screenshots as string[]}
          open={imageCarouselModalOpen}
          onClose={() => setImageCarouselModalOpen(false)}
          title={t('common:screenshots')}
          initialIndex={selectedScreenshotIndex}
        />
      )}
    </div>
  )
}

export default GalleryCarousel
