import { endOfDay, formatDuration, intervalToDuration, startOfDay } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ChatBubble, FiberNew, Visibility } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Chip, Divider, Grid, Typography } from '@mui/material'

import { Analyst } from '../../../../components/Analyst/Analyst'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../components/LiveEventModalLink/LiveEventModalLink'
import { startOfNextDay } from '../../../../helpers/date'
import languageService from '../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { GameCardContent } from '../../../game/components/GameCard/GameCard'
import { useDateTimeFormatter } from '../../hooks/useDateTimeFormatter'
import { useLiveEventTagGroupWithEventTypeId } from '../../hooks/useLiveEventTagGroups'
import { LiveEventFeedItem, LiveEventFeedItemType } from '../../types/LiveEventFeedItem'
import { EventHighlightButton } from '../EventHighlightButton/EventHighlightButton'
import { LiveEventMotivationsSection } from '../LiveEventMotivationsSection/LiveEventMotivationsSection'
import LiveEventsSecondaryTaxonomyTagList from '../LiveEventsSecondaryTaxonomyTagList/LiveEventsSecondaryTaxonomyTagList'
import './LiveEventFeedItemCard.scss'

interface Props {
  feedItem: LiveEventFeedItem
  onOpenFeedItemClick: (feedItem: LiveEventFeedItem) => void
}

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const LiveEventsFeedItemCard: React.FC<Props> = ({ feedItem, onOpenFeedItemClick }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const dateFormatter = useDateTimeFormatter(dateFormat)

  const tagName = useMemo(() => {
    let tagName: string = ''

    if (feedItem.type === LiveEventFeedItemType.LiveEvent && feedItem.trackingEvent?.typeId) {
      tagName = languageService.getTranslation('tags', feedItem.trackingEvent?.typeId)
    }

    return tagName
  }, [feedItem])

  const tagGroup = useLiveEventTagGroupWithEventTypeId(feedItem.trackingEvent?.typeId || '')

  const feedItemTitle = useMemo(() => {
    if (feedItem.type === LiveEventFeedItemType.LiveEvent && feedItem.trackingEvent) {
      return feedItem.trackingEvent.name
    }

    if (feedItem.type === LiveEventFeedItemType.AnalystNote && feedItem.analystNote) {
      return feedItem.analystNote.comment.content.title[userLanguage]
        ? feedItem.analystNote.comment.content.title[userLanguage]
        : feedItem.analystNote.comment.content.title['en']
    }
  }, [feedItem, userLanguage])

  const duration = useMemo(() => {
    const start = startOfDay(feedItem.start).getTime()
    const end = endOfDay(feedItem.end).getTime()
    const durationStart = 0
    const durationEnd = startOfNextDay(feedItem.end) - start
    const offsetDiffMillis = (new Date(start).getTimezoneOffset() - new Date(end).getTimezoneOffset()) * 60 * 1000

    return { start: durationStart, end: durationEnd + offsetDiffMillis }
  }, [feedItem])

  const screenshotURL = useMemo(() => {
    return feedItem.type === LiveEventFeedItemType.LiveEvent && feedItem.trackingEvent?.screenshotUrl ? feedItem.trackingEvent?.screenshotUrl : undefined
  }, [feedItem])

  const descriptionRawHtml = useMemo(() => {
    switch (feedItem.type) {
      case LiveEventFeedItemType.LiveEvent:
        return feedItem.trackingEvent?.comment?.content?.comment?.[userLanguage]
          ? feedItem.trackingEvent?.comment?.content?.comment?.[userLanguage]
          : feedItem.trackingEvent?.comment?.content?.comment?.['en']

      case LiveEventFeedItemType.AnalystNote:
        return feedItem.analystNote?.comment?.content?.comment?.[userLanguage]
          ? feedItem.analystNote?.comment?.content?.comment?.[userLanguage]
          : feedItem.analystNote?.comment?.content?.comment?.['en']
    }
  }, [feedItem, userLanguage])

  const gameId = useMemo(() => {
    switch (feedItem.type) {
      case LiveEventFeedItemType.LiveEvent:
        return feedItem.trackingEvent ? feedItem.trackingEvent?.gameId : ''

      case LiveEventFeedItemType.AnalystNote:
        return feedItem.analystNote?.gameId ? feedItem.analystNote?.gameId : ''
    }
  }, [feedItem])

  return (
    <div className="LiveEventFeedItemCard">
      <Box mb={2}>
        <Card className="LiveEventFeedItemCard__CardComponent">
          {feedItem.type === LiveEventFeedItemType.LiveEvent && feedItem.new && (
            <div className="LiveEventFeedItemCard__NewItemHeader" style={{ backgroundColor: tagGroup.colorHex }}>
              <strong>{t('live-events:event_type_first_appearance')}</strong>
            </div>
          )}
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              {feedItem.trackedGame && (
                <Grid item xs={3}>
                  <GameCardContent game={feedItem.trackedGame.game} displayGameType />
                </Grid>
              )}
              <Grid item xs={6} container direction="column" alignItems="center">
                {feedItem.type === LiveEventFeedItemType.AnalystNote && <Typography variant="h3">{feedItemTitle}</Typography>}

                {feedItem.type === LiveEventFeedItemType.LiveEvent && (
                  <>
                    <Typography variant="h3">{tagName}</Typography>
                    {tagGroup && (
                      <Box mt={0.5}>
                        <Chip label={tagGroup.name} style={{ marginBottom: '0px', color: 'white', backgroundColor: tagGroup.colorHex }} size="small"></Chip>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Box mb={2}>
              <Grid container spacing={4} wrap="wrap">
                <Grid className="LiveEventFeedItemCard__HTMLContent" item xs={12} md={screenshotURL ? 6 : 12}>
                  {feedItem.type === LiveEventFeedItemType.LiveEvent && (
                    <Box mb={2}>
                      <Typography variant="h2" style={{ color: feedItem.new ? tagGroup.colorHex : '' }}>
                        {feedItem.new === true && <FiberNew sx={{ fontSize: '25px', marginBottom: '-5px', mr: 1 }} />}
                        {feedItemTitle}
                      </Typography>
                      <Typography className="light-text-color" variant="body1">
                        {t('live-events:duration_start')}: {dateFormatter.format(feedItem.start)}
                      </Typography>
                      <Typography className="light-text-color" variant="body1">
                        {t('live-events:duration')}: {formatDuration(intervalToDuration(duration), { format: ['months', 'days', 'hours'] })}
                      </Typography>
                      {feedItem.trackingEvent?.tags && feedItem.trackingEvent?.tags.length > 0 && (
                        <Box paddingTop={2}>
                          <Divider sx={{ marginBottom: '10px' }}>{t('live-events:live_events_taxonomy')}</Divider>
                          <LiveEventsSecondaryTaxonomyTagList tags={feedItem.trackingEvent?.tags} justifyContent="flex-start" />
                        </Box>
                      )}
                    </Box>
                  )}

                  {feedItem.trackingEvent?.filteredMotivations && (
                    <LiveEventMotivationsSection
                      motivationTypes={feedItem.trackingEvent?.filteredMotivations.types}
                      motivationSegments={feedItem.trackingEvent?.filteredMotivations.archetypes}
                    />
                  )}

                  <Box paddingBottom={2}>
                    <Divider />
                  </Box>

                  {feedItem.analyst && (
                    <Box mb={1}>
                      <Grid item alignSelf="center">
                        <Analyst picture={feedItem.analyst.picture} name={feedItem.analyst.name} title={feedItem.analyst.title} />
                      </Grid>
                    </Box>
                  )}
                  {gameId && <HtmlContentParser rawHtml={descriptionRawHtml} parserOptions={liveEventReviewModalParserOptions(gameId || '')} />}
                </Grid>

                {screenshotURL && (
                  <Grid item alignSelf="top" xs={12} md={6} textAlign="center">
                    <img className="LiveEventFeedItemCard__ItemScreenshot" alt="Screenshot" src={screenshotURL} onClick={() => onOpenFeedItemClick(feedItem)} />
                  </Grid>
                )}
              </Grid>
            </Box>

            <Divider />

            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item>
                  {feedItem.type === LiveEventFeedItemType.LiveEvent && (
                    <Button onClick={() => onOpenFeedItemClick(feedItem)} variant="contained" color="primary" startIcon={<Visibility />} fullWidth>
                      {t('live-events:game_alert_action_open_event_description')}
                    </Button>
                  )}

                  {feedItem.type === LiveEventFeedItemType.AnalystNote && (
                    <Button onClick={() => onOpenFeedItemClick(feedItem)} variant="contained" color="success" startIcon={<ChatBubble />} fullWidth>
                      {t('live-events:game_alert_action_open_comment_notes')}
                    </Button>
                  )}
                </Grid>

                <Grid item>
                  {feedItem.type === LiveEventFeedItemType.LiveEvent && feedItem.trackingEvent && (
                    <EventHighlightButton
                      eventId={feedItem.trackingEvent.eventId}
                      trackedGameId={feedItem.trackedGame?.game.id}
                      eventStartTime={feedItem.start}
                      eventEndTime={feedItem.end}
                      eventType={feedItem.trackingEvent?.typeId}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}

export default LiveEventsFeedItemCard
