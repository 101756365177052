import { add, isFuture, startOfDay, sub } from 'date-fns'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CloseRounded } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import { Screenshot } from '../../../../api/core'
import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import GalleryCarousel, { GalleryCarouselImageListingType } from '../../../../components/GalleryCarousel/GalleryCarousel'
import { LockedFeatureIndicator } from '../../../../components/LockedFeatureIndicator/LockedFeatureIndicator'
import { PerformanceChartV2, YAxisConfig } from '../../../../components/PerformanceChartV2/PerformanceChartV2'
import { PerformanceChartV2DataType } from '../../../../components/PerformanceChartV2/PerformanceChartV2DataType'
import { ReviewContent, ReviewHeader } from '../../../../components/Review/Review'
import { valueIsBetween } from '../../../../helpers/valueIsBetween'
import { useAnalystComment } from '../../../../hooks/useAnalystComment'
import { useAnalystReviewsAndCommentsAccessCheck, useGameUpdateImpactsAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { useFeatures } from '../../../feature/hooks/useFeatures'
import { useGames } from '../../../game/hooks/gameHooks'
import { NewsEntryScreenshotFeature } from '../../../news/types/UnifiedNewsEntry'
import { GranularityValue } from '../../../revenue-and-downloads/types/Filters'
import { useIsGameOpenForEveryone } from '../../../settings'
import { useGameUpdateHistory } from '../../../update-history/hooks/useGameUpdateHistory'
import { Features, Filters, UpdateImpactFilters, UpdateImpactsTable } from '../../../update-impacts'
import { ComparisonInterval } from '../../../update-impacts/types/ComparisonInterval'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'

/**
 * Component for game update modal presenting data on a specific game update, performance chart, changed and updated features ans screenshots.
 */
type UpdateModalProps = Omit<DialogProps, 'onClose'> & {
  gameId: string
  gameVersion: string
  marketIso: string
  onClose: () => void
  onUpdateChange: (update: TableRowUpdateImpact) => void
}

export const UpdateModal: FC<UpdateModalProps> = ({ gameId, gameVersion, marketIso, onUpdateChange, onClose, ...dialogProps }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const language = useCurrentUserLanguage()
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>()
  const { data: featuresData } = useFeatures()
  const excludedDataTypes = [PerformanceChartV2DataType.DAU, PerformanceChartV2DataType.MAU]
  const [yAxisLeftConfig, setYAxisLeftConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Revenue, excludedDataTypes })
  const [yAxisRightConfig, setYAxisRightConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Downloads, excludedDataTypes })
  const [granularity, setGranularity] = useState(GranularityValue.Week)
  const [imageListingType, setImageListingType] = useState<GalleryCarouselImageListingType>('carousel')

  // retrieve game
  const {
    games: [game],
    isLoading: isGameLoading,
  } = useGames([gameId], marketIso)

  // user rights checks
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const hasUpdateImpactsAccessRights = useGameUpdateImpactsAccessCheck()
  const impactDataUnlocked = hasUpdateImpactsAccessRights || isGameOpenForEveryone

  // retrieve update history for game
  const {
    impacts,
    highlightedImpacts,
    updateImpactsMappedForTable,
    isLoading: isUpdateHistoryLoading,
    isFetching: isUpdateHistoryFetching,
  } = useGameUpdateHistory(marketIso, game)
  const isUpdateLoading = isGameLoading || isUpdateHistoryLoading || isUpdateHistoryFetching

  const selectedUpdateImpact = useMemo(() => {
    return updateImpactsMappedForTable.find((impact) => impact.gameId === gameId && impact.version === gameVersion)
  }, [gameId, gameVersion, updateImpactsMappedForTable])

  const updateImpactTableRows = useMemo(() => {
    return selectedUpdateImpact ? [selectedUpdateImpact] : []
  }, [selectedUpdateImpact])

  // retrieve analyst comment if it exists
  const { analystComment, isLoading: isAnalystCommentLoading } = useAnalystComment(selectedUpdateImpact?.commentId, !selectedUpdateImpact?.commentId)

  const [updateImpactFilters, setUpdateImpactFilters] = useState<Filters>({
    comparisonInterval: ComparisonInterval.DAYS_7,
    subgenres: {
      [game?.conventionalSubgenreId]: true,
    },
  })

  const handleUpdateImpactFiltersChange = useCallback((filters: Filters) => {
    setUpdateImpactFilters((currentFilters) => ({ ...currentFilters, ...filters }))
  }, [])

  useEffect(() => {
    setUpdateImpactFilters((currentFilters) => ({ ...currentFilters, subgenres: { [game?.conventionalSubgenreId]: true } }))
  }, [game])

  const handleChangeUpdate = useCallback(
    (skip: number) => {
      const newUpdateIndex = updateImpactsMappedForTable.findIndex((impact) => impact === selectedUpdateImpact) + skip
      onUpdateChange(updateImpactsMappedForTable[newUpdateIndex])
    },
    [onUpdateChange, selectedUpdateImpact, updateImpactsMappedForTable]
  )

  const changeUpdate = useCallback(
    (update: TableRowUpdateImpact) => {
      onUpdateChange(update)
    },
    [onUpdateChange]
  )

  const handleDateRangeChange = useCallback((dateRange: DateRangeValue | undefined) => {
    setDateRange(dateRange)
  }, [])

  const handleShowEvolution = useCallback(
    (screenshotFeature: NewsEntryScreenshotFeature) => {
      navigate(`/implementation-examples/examples/features/${[screenshotFeature.featureLegacyId, screenshotFeature.choiceLegacyId].join('-')}/${gameId}`)
    },
    [gameId, navigate]
  )

  const handleGranularityChange = (granularity: GranularityValue) => {
    setGranularity(granularity)
  }

  const otherUpdatesForChart = useMemo(() => {
    return impacts
      .filter((update) => update !== selectedUpdateImpact)
      .map((impact) => ({
        timestamp: impact.releaseDate,
        data: impact,
      }))
  }, [impacts, selectedUpdateImpact])

  const highlightedUpdatesForChart = useMemo(() => {
    return highlightedImpacts
      .filter((update) => update !== selectedUpdateImpact)
      .map((impact) => ({
        timestamp: impact.releaseDate,
        data: impact,
      }))
  }, [highlightedImpacts, selectedUpdateImpact])

  const selectedUpdatesForChart = useMemo(() => {
    return selectedUpdateImpact
      ? [selectedUpdateImpact].map((impact) => ({
          timestamp: impact.releaseDate,
          data: impact,
        }))
      : []
  }, [selectedUpdateImpact])

  const isFirstUpdate = updateImpactsMappedForTable.findIndex((impact) => impact === selectedUpdateImpact) === 0
  const isLastUpdate = updateImpactsMappedForTable.findIndex((impact) => impact === selectedUpdateImpact) === updateImpactsMappedForTable.length - 1

  // update performance chart date range if selected update is out of range
  useEffect(() => {
    const gameReleaseDate = new Date(selectedUpdateImpact?.releaseDate || 0)

    if (!dateRange?.fromDate || !dateRange?.toDate || !valueIsBetween(gameReleaseDate, dateRange?.fromDate, dateRange?.toDate)) {
      const fromDate = startOfDay(sub(gameReleaseDate, { months: 3 }))
      const toDate = isFuture(add(gameReleaseDate, { months: 3 })) ? startOfDay(new Date()) : startOfDay(add(gameReleaseDate, { months: 3 }))

      setDateRange({ fromDate, toDate })
    }
  }, [dateRange?.fromDate, dateRange?.toDate, selectedUpdateImpact])

  const handleImageListingTypeChange = (newType: GalleryCarouselImageListingType) => {
    setImageListingType(newType)
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      PaperProps={{ sx: { maxHeight: '80vh', minHeight: '80vh' } }}
      {...dialogProps}
      keepMounted
      disableRestoreFocus
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          {t('common:game_update')}
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <UpdateImpactFilters filters={updateImpactFilters} onChange={handleUpdateImpactFiltersChange} showComparisonIntervalSelector />
        <UpdateImpactsTable
          rows={updateImpactTableRows}
          filters={updateImpactFilters}
          isLoading={isUpdateLoading}
          tableConfig={{
            impactDataUnlocked: impactDataUnlocked,
            showGameColumn: true,
            showPowerscore: true,
            powerscoreUnlocked: true,
            analystReviewsAndCommentsUnlocked: hasAnalystReviewsAndCommentsAccessRights,
            showFeaturesColumn: false,
            showOpenUpdateColumn: false,
            showSortingIcon: false,
          }}
        />
        <Divider sx={{ my: 3, opacity: 0.6 }} />
        <Card>
          <CardContent>
            {!isUpdateLoading && (
              <PerformanceChartV2
                dateRange={dateRange}
                granularity={granularity}
                appId={game?.appId}
                marketIso={marketIso}
                onDateRangeChanged={handleDateRangeChange}
                onGranularityChanged={handleGranularityChange}
                verticalMarks={otherUpdatesForChart}
                highlightedVerticalMarks={highlightedUpdatesForChart}
                selectedVerticalMarks={selectedUpdatesForChart}
                onMarkClicked={changeUpdate}
                yAxisLeftConfig={yAxisLeftConfig}
                onYAxisLeftConfigChanged={(value) => setYAxisLeftConfig(value)}
                yAxisRightConfig={yAxisRightConfig}
                onYAxisRightConfigChanged={(value) => setYAxisRightConfig(value)}
              />
            )}
          </CardContent>
          <Collapse in={!!analystComment?.analysisComment} timeout={{ enter: 300, exit: 0 }}>
            {isAnalystCommentLoading ? (
              <GRCircularProgress />
            ) : (
              <CardContent>
                <ReviewHeader analyst={analystComment?.analyst} />
                <Typography variant="body1" component="div">
                  {hasAnalystReviewsAndCommentsAccessRights ? (
                    <ReviewContent
                      contentHtml={
                        analystComment?.analysisComment?.content?.comment?.[language]
                          ? analystComment?.analysisComment?.content?.comment?.[language]
                          : analystComment?.analysisComment?.content?.comment?.['en']
                      }
                    />
                  ) : (
                    <p>{t('reviews:label_not_for_free')}</p>
                  )}
                </Typography>
              </CardContent>
            )}
          </Collapse>

          <CardContent>
            <Collapse in={!!selectedUpdateImpact?.releaseNote}>
              <Divider light sx={{ my: 3 }}>
                {t('common:release_notes')}
              </Divider>
              <Typography variant="h3" mb={2}>
                {t('common:version')} {selectedUpdateImpact?.version}
              </Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {selectedUpdateImpact?.releaseNote}
              </Typography>
            </Collapse>

            <Collapse in={(selectedUpdateImpact?.changedFeatures || []).length > 0}>
              <Divider light sx={{ my: 3 }}>
                {t('newsfeed:changes_in_feature_set_topic')}
              </Divider>
              {hasUpdateImpactsAccessRights ? (
                <Features.Changed.List features={selectedUpdateImpact?.changedFeatures} variant="card"></Features.Changed.List>
              ) : (
                <LockedFeatureIndicator justifyContent="center" />
              )}
            </Collapse>

            <Collapse in={(selectedUpdateImpact?.updatedFeatures || []).length > 0}>
              <Divider light sx={{ my: 3 }}>
                {t('common:updated_features')}
              </Divider>
              {hasUpdateImpactsAccessRights ? (
                <Features.Updated.List features={selectedUpdateImpact?.updatedFeatures} variant="card">
                  {(feature) =>
                    featuresData
                      ?.map((data) => data.features)
                      .flatMap((legacyId) => legacyId)
                      .find((data) => data.legacyId === feature.featureLegacyId) ? (
                      <LoadingButton variant="contained" onClick={() => handleShowEvolution(feature)}>
                        {t('common:show_evolution')}
                      </LoadingButton>
                    ) : null
                  }
                </Features.Updated.List>
              ) : (
                <LockedFeatureIndicator justifyContent="center" />
              )}
            </Collapse>

            <Collapse in={(selectedUpdateImpact?.screenshots || []).length > 0}>
              <Divider light sx={{ my: 3 }}>
                {t('common:screenshots')}
              </Divider>
              {selectedUpdateImpact?.screenshots &&
                selectedUpdateImpact?.screenshots?.length > 0 &&
                (hasUpdateImpactsAccessRights ? (
                  <>
                    <GalleryCarousel
                      screenshots={selectedUpdateImpact.screenshots as Screenshot[]}
                      imageListingType={imageListingType}
                      thumbnailGridSize={4}
                      onImageListingTypeChange={(newType: GalleryCarouselImageListingType) => handleImageListingTypeChange(newType)}
                    />
                  </>
                ) : (
                  <LockedFeatureIndicator justifyContent="center" />
                ))}
            </Collapse>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Button variant="contained" color="primary" startIcon={<ChevronLeftIcon />} onClick={() => handleChangeUpdate(-1)} disabled={isFirstUpdate}>
            {t('common:previous_update')}
          </Button>
          <Button variant="contained" color="primary" endIcon={<ChevronRightIcon />} onClick={() => handleChangeUpdate(1)} disabled={isLastUpdate}>
            {t('common:next_update')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
