import React from 'react'

import { Grid } from '@mui/material'

import languageService from '../../../../services/LanguageService'
import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { motivationArchetypeImages } from '../../utils/MotivationArchetypeImages'
import './MotivationArchetypeHorizontalBadge.scss'

interface Props {
  motivationKey: MotivationKey
}

export const MotivationArchetypeHorizontalBadge: React.FC<Props> = ({ motivationKey }) => {
  const archetypeName = languageService.getTranslation('motivations', motivationKey)

  return (
    <div className="MotivationArchetypeHorizontalBadge">
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <img className="MotivationArchetypeHorizontalBadge__Image" src={motivationArchetypeImages[motivationKey]} alt={motivationKey} />
        </Grid>
        <Grid item xs>
          <div className="MotivationArchetypeHorizontalBadge__Name">{archetypeName}</div>
        </Grid>
      </Grid>
    </div>
  )
}
