import { FC, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { MotivationArchetypeAndTypeBadges } from '../../../motivations/components/MotivationArchetypeAndTypeBadges/MotivationArchetypeAndTypeBadges'
import { MotivationLevel, MotivationTypeWithLevel } from '../../../motivations/components/MotivationTypeBadge/MotivationTypeBadge'

type LiveEventMotivationsSectionProps = {
  motivationTypes?: { major: MotivationType[]; minor: MotivationType[] }
  motivationSegments?: MotivationKey[]
}

export const LiveEventMotivationsSection: FC<LiveEventMotivationsSectionProps> = ({ motivationTypes, motivationSegments }) => {
  const internalUser = useRoleCheck(RoleEnum.internal) // NOTE: Remove Internal role check when we want to release Live Event Motivations

  const liveEventMotivations = useMemo(() => {
    if (motivationTypes || motivationSegments) {
      const types: MotivationTypeWithLevel[] = []

      if (motivationTypes) {
        motivationTypes.major.forEach((key) => {
          const motivationTypeWithLevel: MotivationTypeWithLevel = { type: key, level: MotivationLevel.MAJOR }
          types.push(motivationTypeWithLevel)
        })

        motivationTypes.minor.forEach((key) => {
          const motivationTypeWithLevel: MotivationTypeWithLevel = { type: key, level: MotivationLevel.MINOR }
          types.push(motivationTypeWithLevel)
        })
      }

      return { types, archetypes: motivationSegments || [] }
    }

    return undefined
  }, [motivationTypes, motivationSegments])

  return (
    <>
      {internalUser && liveEventMotivations && (liveEventMotivations.archetypes.length > 0 || liveEventMotivations?.types.length > 0) && (
        <>
          <Divider sx={{ my: 2 }}>
            <Trans i18nKey="live-events:archetypes_and_motivations_title" />
            <GRTooltip
              content={
                <Typography variant="body1" component="div">
                  <Trans i18nKey={'live-events:archetypes_and_motivations_title_tooltip'} />
                </Typography>
              }
            >
              <Info color="primary" sx={{ position: 'relative', top: '4px', marginLeft: '4px', fontSize: '18px' }} />
            </GRTooltip>
          </Divider>

          <Box mb={1}>
            <MotivationArchetypeAndTypeBadges motivationKeys={liveEventMotivations.archetypes} motivationTypesWithLevel={liveEventMotivations.types} />
          </Box>
        </>
      )}
    </>
  )
}
