import { MotivationKey, MotivationType } from '../features/market-explorer/types/MotivationType'
import { isNil } from '../helpers/isNil'
import languageService from './LanguageService'

export type Motivation = {
  [key in MotivationType]: number
}

export type KeyMotivation = {
  [key in MotivationKey]: Motivation
}

export type MotivationArchetype = {
  [key in MotivationKey]: number
}

type Demographic = {
  [key in MotivationKey]: {
    [group: string]: number
  }
}

class MotivationService {
  keyMotivations: KeyMotivation = {
    [MotivationKey.Expressionist]: {
      'customization and decoration': 4.8,
      'role playing and emotions': 4.5,
      'excitement and thrill': 4.0,
      'collecting treasure': 3.6,
      'completing milestones': 2.8,
      'discovering new worlds': 1.5,
      'thinking and solving': 1.4,
      'improving skills': 1.3,
      'compete with others': 1.0,
      'resource optimization': 1.0,
      'working with others': 1.0,
      'strategic planning': 1.0,
    },

    [MotivationKey.KingOfTheHill]: {
      'customization and decoration': 1.6,
      'role playing and emotions': 1.1,
      'excitement and thrill': 2.5,
      'collecting treasure': 3.1,
      'completing milestones': 3.7,
      'discovering new worlds': 1.4,
      'thinking and solving': 1.9,
      'improving skills': 2.5,
      'compete with others': 3.1,
      'resource optimization': 1.3,
      'working with others': 1.2,
      'strategic planning': 1.9,
    },
    [MotivationKey.Networker]: {
      'customization and decoration': 1.8,
      'role playing and emotions': 1.3,
      'excitement and thrill': 3.4,
      'collecting treasure': 3.7,
      'completing milestones': 3.1,
      'discovering new worlds': 3.7,
      'thinking and solving': 1.8,
      'improving skills': 2.8,
      'compete with others': 4.9,
      'resource optimization': 4.3,
      'working with others': 4.9,
      'strategic planning': 4.5,
    },
    [MotivationKey.SkillMaster]: {
      'customization and decoration': 1.6,
      'role playing and emotions': 1.1,
      'excitement and thrill': 3.5,
      'collecting treasure': 1.9,
      'completing milestones': 3.2,
      'discovering new worlds': 1.5,
      'thinking and solving': 1.9,
      'improving skills': 4.3,
      'compete with others': 1.9,
      'resource optimization': 1.1,
      'working with others': 1.1,
      'strategic planning': 1.1,
    },
    [MotivationKey.Strategist]: {
      'customization and decoration': 2.0,
      'role playing and emotions': 1.1,
      'excitement and thrill': 2.8,
      'collecting treasure': 3.3,
      'completing milestones': 3.4,
      'discovering new worlds': 3.2,
      'thinking and solving': 2.5,
      'improving skills': 2.8,
      'compete with others': 3.2,
      'resource optimization': 4.8,
      'working with others': 2.6,
      'strategic planning': 3.9,
    },
    [MotivationKey.Thinker]: {
      'customization and decoration': 2.1,
      'role playing and emotions': 1.1,
      'excitement and thrill': 1.8,
      'collecting treasure': 1.3,
      'completing milestones': 3.1,
      'discovering new worlds': 1.3,
      'thinking and solving': 4.6,
      'improving skills': 3.2,
      'compete with others': 1.5,
      'resource optimization': 1.2,
      'working with others': 1.0,
      'strategic planning': 1.1,
    },
    [MotivationKey.ThrillSeeker]: {
      'customization and decoration': 2.0,
      'role playing and emotions': 1.3,
      'excitement and thrill': 4.5,
      'collecting treasure': 3.0,
      'completing milestones': 3.1,
      'discovering new worlds': 1.6,
      'thinking and solving': 1.2,
      'improving skills': 3.0,
      'compete with others': 2.3,
      'resource optimization': 1.1,
      'working with others': 1.1,
      'strategic planning': 1.1,
    },
    [MotivationKey.TreasureHunter]: {
      'customization and decoration': 2.1,
      'role playing and emotions': 1.7,
      'excitement and thrill': 2.8,
      'collecting treasure': 4.3,
      'completing milestones': 3.4,
      'discovering new worlds': 3.6,
      'thinking and solving': 1.9,
      'improving skills': 2.7,
      'compete with others': 1.8,
      'resource optimization': 1.9,
      'working with others': 1.5,
      'strategic planning': 1.6,
    },
  }

  demographics: Demographic = {
    Expressionist: {
      age16to24: 42,
      age25to44: 43,
      age45plus: 15,
      female: 65,
      male: 35,
    },
    Thinker: {
      age16to24: 19,
      age25to44: 40,
      age45plus: 41,
      female: 58,
      male: 42,
    },
    'Treasure Hunter': {
      age16to24: 36,
      age25to44: 44,
      age45plus: 20,
      female: 39,
      male: 61,
    },
    Strategist: {
      age16to24: 27,
      age25to44: 47,
      age45plus: 26,
      female: 32,
      male: 68,
    },
    'Skll master': {
      age16to24: 29,
      age25to44: 45,
      age45plus: 26,
      female: 47,
      male: 53,
    },
    'Thrill seeker': {
      age16to24: 39,
      age25to44: 40,
      age45plus: 21,
      female: 41,
      male: 59,
    },
    'King of the hill': {
      age16to24: 25,
      age25to44: 47,
      age45plus: 28,
      female: 40,
      male: 60,
    },
    Networker: {
      age16to24: 25,
      age25to44: 47,
      age45plus: 28,
      female: 31,
      male: 69,
    },
  }

  featuresTheyPrefer = {
    [MotivationKey.Expressionist]: [179, 264, 16, 208, 14, 324, 209, 127],
    [MotivationKey.Thinker]: [50, 19, 227, 44, 82, 279, 81, 125],
    [MotivationKey.TreasureHunter]: [275, 102, 87, 70, 200, 263, 60, 59],
    [MotivationKey.Strategist]: [61, 103, 102, 249, 67, 126, 124, 63],
    [MotivationKey.SkillMaster]: [33, 50, 56, 71, 81, 63, 263, 296],
    [MotivationKey.ThrillSeeker]: [18, 20, 117, 151, 82, 56, 71, 332],
    [MotivationKey.KingOfTheHill]: [117, 151, 56, 261, 263, 76, 125, 279, 281],
    [MotivationKey.Networker]: [126, 308, 309, 311, 128, 127, 122, 260, 261],
  }

  gamesTheyPrefer = {
    [MotivationKey.Expressionist]: [620112416, 656971078, 860822992, 466966101, 1071310449, 1280910599, 1214763767, 1333256716],
    [MotivationKey.Thinker]: [553834731, 938207958, 1207472156, 1105855019, 892521917, 700970012, 880178264, 495583717],
    [MotivationKey.TreasureHunter]: [479516143, 1094591345, 1375425432, 1241932094, 1352230941, 1127240206, 497595276, 1333256716],
    [MotivationKey.Strategist]: [529479190, 1274354704, 638689075, 1118115766, 1354260888, 561941526, 970417047, 1001250333],
    [MotivationKey.SkillMaster]: [1453651052, 1261357853, 1443446174, 1089225191, 1453411110, 1345968745, 924373886, 625334537],
    [MotivationKey.ThrillSeeker]: [447553564, 538212549, 1029094059, 529996768, 887947640, 1453651052, 995999703, 1055502792],
    [MotivationKey.KingOfTheHill]: [719525810, 1330123889, 406889139, 1358222641, 1206967173, 543186831, 1443876169, 431946152],
    [MotivationKey.Networker]: [968953976, 1354260888, 1053012308, 958763157, 1473544990, 1229016807, 1071976327, 852912420],
  }

  getKeyMotivations(): MotivationKey[] {
    return Object.keys(this.keyMotivations) as MotivationKey[]
  }

  getKeyMotivation(key: MotivationKey, motivation: MotivationType): number {
    return this.keyMotivations[key][motivation]
  }

  getMotivationTypes(): MotivationType[] {
    return Object.values(MotivationType)
  }

  getDemographic(key: MotivationKey, group: string): number {
    return this.demographics[key][group]
  }

  getFeaturesTheyPrefer(key: MotivationKey): number[] {
    return this.featuresTheyPrefer[key]
  }

  getGamesTheyPrefer(key: MotivationKey): number[] {
    return this.gamesTheyPrefer[key]
  }

  getSegmentName(motivationKey: string, removeCategory: boolean = false): string {
    const translation: string = languageService.getTranslation('motivations', motivationKey)

    if (isNil(translation)) {
      return motivationKey
    }

    if (removeCategory) {
      return translation.split(/ - |: |：/)[1].trim()
    }

    return translation
  }

  buildAndFilterMotivationsForLiveEvent(motivationTypes?: Motivation, motivationArchetypes?: MotivationArchetype) {
    const filteredMotivations: { types: { major: MotivationType[]; minor: MotivationType[] }; archetypes: MotivationKey[] } = {
      types: { major: [], minor: [] },
      archetypes: [],
    }

    if (motivationTypes && motivationArchetypes) {
      Object.keys(motivationTypes).forEach((key) => {
        switch (motivationTypes[key as MotivationType]) {
          case 5:
            filteredMotivations.types.major.push(key as MotivationType)
            break

          case 4:
            filteredMotivations.types.minor.push(key as MotivationType)
            break
        }
      })

      let highestMotivationArchetypeValue = 0
      Object.keys(motivationArchetypes).forEach((key) => {
        if (highestMotivationArchetypeValue < motivationArchetypes[key as MotivationKey]) {
          highestMotivationArchetypeValue = motivationArchetypes[key as MotivationKey]
        }
      })

      Object.keys(motivationArchetypes).forEach((key) => {
        if (motivationArchetypes[key as MotivationKey] === highestMotivationArchetypeValue) {
          filteredMotivations.archetypes.push(key as MotivationKey)
        }
      })
    }

    return filteredMotivations
  }
}

const motivationService = new MotivationService()
export default motivationService
