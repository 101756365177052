import React from 'react'

import { Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { motivationArchetypeImages } from '../../utils/MotivationArchetypeImages'
import './MotivationArchetypeCardBadge.scss'

interface Props {
  motivationKey: MotivationKey
  selected?: boolean
  onSelect?: (motivationKey: MotivationKey) => void
}

export const MotivationArchetypeCardBadge: React.FC<Props> = ({ motivationKey, selected, onSelect }) => {
  const archetypeName = languageService.getTranslation('motivations', motivationKey)
  const archetypeDescription = languageService.getTranslation('motivations', `${motivationKey}_description`)

  return (
    <GRTooltip
      content={
        <>
          <Typography variant="h3">{archetypeName}</Typography>
          <p style={{ marginBottom: '0px' }}>{archetypeDescription}</p>
        </>
      }
      styles={{ display: 'inline-block' }}
    >
      <div
        className={selected ? 'MotivationArchetypeCardBadge MotivationArchetypeCardBadge--Selected' : 'MotivationArchetypeCardBadge'}
        onClick={() => {
          onSelect && onSelect(motivationKey)
        }}
      >
        <img className="MotivationArchetypeCardBadge__Image" src={motivationArchetypeImages[motivationKey]} alt={motivationKey} />
        <div className="MotivationArchetypeCardBadge__Name">{archetypeName}</div>
      </div>
    </GRTooltip>
  )
}
