import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import { Settings } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, CircularProgress, FormControlLabel, Grow, Switch, Typography } from '@mui/material'

import { useGetUserSettingsQuery, useUpdateDailyDigestSubscriptionMutation } from '../../../../api/combined'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { SubgenreMap } from '../../../account/types/UserSettings'
import { useCurrentMarket } from '../../../markets'
import SlackButton from '../../../slack/components/SlackButton'
import { displaySnackBar } from '../../../snackbar'
import { useUnifiedNewsEntries } from '../../hooks/unifiedNewsEntryHooks'
import newsEntriesService from '../../services/NewsEntriesService'
import { NewsListPagination } from '../../types/NewsListPagination'
import { NewsEntryType, UnifiedNewsEntry } from '../../types/UnifiedNewsEntry'
import NewsCard from '../NewsCard/NewsCard'
import NewsListPreferencesDialog from '../NewsListPreferencesDialog/NewsListPreferencesDialog'
import SelectedSubgenreChips from '../SelectedSubgenresChips/SelectedSubgenreChips'
import './NewsList.scss'

const NewsList: React.FC = () => {
  const { t } = useTranslation()
  const currentUserLanguage = useCurrentUserLanguage()
  const { data: userSettings } = useGetUserSettingsQuery()
  const [subscribedToDailyDigest, setSubscribedToDailyDigest] = useState(userSettings?.daily_digest as boolean)
  const [preferencesModalOpen, setPreferencesModalOpen] = useState(false)
  const [originalSelectedGenres, setOriginalSelectedGenres] = useState<SubgenreMap>()
  const [selectedSubgenresMap, setSelectedSubgenresMap] = useState(userSettings?.userDefaultConventionalSubgenres || {})
  const [pagination, setPagination] = useState<NewsListPagination>({ stickyEditorials: 0, other: 0 })
  const [selectedNewsEntryTypes, setSelectedNewsEntryTypes] = useState<Array<string>>(userSettings?.newsfeedTypes || newsEntriesService.getNewsEntryTypes())
  const [updateDailyDigestSubscription] = useUpdateDailyDigestSubscriptionMutation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const slackRoleUnlocked = useRoleCheck(RoleEnum.slack)
  const dispatch = useAppDispatch()
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(true)

  const { unifiedNewsEntries, unifiedNewsEntriesLoading, unifiedNewsEntriesError, unifiedNewsEntriesLastEvents } = useUnifiedNewsEntries({
    marketIso: mainMarketIso,
    language: currentUserLanguage,
    selectedSubgenresMap,
    newsfeedTypes: selectedNewsEntryTypes,
    limit: 20,
    pagination,
  })

  useEffect(() => {
    if (unifiedNewsEntries && unifiedNewsEntriesLastEvents !== undefined) {
      setDisplayShowMoreButton(!unifiedNewsEntriesLastEvents)
    }
  }, [unifiedNewsEntries, unifiedNewsEntriesLastEvents])

  const handlePreferencesDialogClickOpen = () => {
    setPreferencesModalOpen(true)
  }

  const handlePreferencesDialogClose = (selectedSubgenresMap: SubgenreMap, selectedNewsEntryTypes: Array<string>) => {
    setSelectedSubgenresMap(selectedSubgenresMap)
    setSelectedNewsEntryTypes(selectedNewsEntryTypes)
    setPagination({ stickyEditorials: 0, other: 0 })
    setPreferencesModalOpen(false)
  }

  const handlePreferencesDialogCancel = () => {
    if (originalSelectedGenres) setSelectedSubgenresMap(originalSelectedGenres)
    else setSelectedSubgenresMap(userSettings?.userDefaultConventionalSubgenres || {})
    setSelectedNewsEntryTypes(userSettings?.newsfeedTypes || newsEntriesService.getNewsEntryTypes())
    setPreferencesModalOpen(false)
  }

  const handleSubscribeChange = () => {
    if (subscribedToDailyDigest) {
      analyticsService.trackEvent('Canceled news feed digest subscription')
    }

    updateDailyDigestSubscription(!subscribedToDailyDigest)
    setSubscribedToDailyDigest(!subscribedToDailyDigest)
    const message = !subscribedToDailyDigest ? t('newsfeed:you_will_receive_daily_digest') : t('newsfeed:you_will_no_longer_receive_daily_digest')
    dispatch(displaySnackBar({ message, severity: 'success', open: true }))
  }

  // TODO: start using this as pagination to query news entries once BE has them in place
  // This is now partially resolved by monitoring previously returned News Entries count vs. newly returned
  const handleShowMoreClick = useCallback(() => {
    const [stickyEditorialCount] = unifiedNewsEntries.reduce(
      (acc, newsEntry) => {
        const entryType = newsEntry.feedEntry.type
        if ([NewsEntryType.LiveEventAnalystNotes].includes(entryType)) {
          return [acc[0] + 1, acc[1]]
        } else if (entryType === NewsEntryType.Editorial && newsEntry.feedEntry.entryData.sticky) {
          return [acc[0], acc[1] + 1]
        }
        return acc
      },
      [0, 0]
    )
    const othersCount = unifiedNewsEntries.length - stickyEditorialCount
    setPagination({ stickyEditorials: stickyEditorialCount, other: othersCount })
  }, [unifiedNewsEntries])

  return (
    <div className="NewsList">
      <div className="NewsList__header">
        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={<Settings />}
          disabled={unifiedNewsEntriesLoading}
          onClick={handlePreferencesDialogClickOpen}
          sx={{ mr: 2 }}
        >
          {t('newsfeed:show_options')}
        </Button>

        {slackRoleUnlocked && (
          <Box mr={2}>
            <SlackButton />
          </Box>
        )}

        {preferencesModalOpen && (
          <NewsListPreferencesDialog
            modalOpen={preferencesModalOpen}
            handleClose={handlePreferencesDialogClose}
            handleCancel={handlePreferencesDialogCancel}
            selectedSubgenresMap={selectedSubgenresMap}
            onSelectedSubgenresChange={(selectedSubgenres: SubgenreMap) => setOriginalSelectedGenres(selectedSubgenres)}
          />
        )}

        <FormControlLabel
          className="NewsList__subscribeToggleContainer"
          control={<Switch checked={subscribedToDailyDigest} onChange={handleSubscribeChange} name="receive_daily_digest_email" color="primary" />}
          label={t<string>('newsfeed:receive_daily_digest_email')}
        />
      </div>
      <div className="NewsList__subgenre">
        {Object.keys(selectedSubgenresMap).length > 0 && <SelectedSubgenreChips selectedSubgenres={selectedSubgenresMap} smallChips={true} />}
      </div>

      {unifiedNewsEntries.length === 0 && !unifiedNewsEntriesLoading && <Typography className="top-margin">{t('newsfeed:no_newsfeed_cards_found')}</Typography>}

      {unifiedNewsEntriesLoading && unifiedNewsEntries.length === 0 ? (
        <div className="text-center top-margin">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <TransitionGroup>
          {unifiedNewsEntries?.map((newsEntry: UnifiedNewsEntry) => (
            <Grow key={newsEntry.feedEntry.id}>
              <Box>
                <NewsCard newsEntry={newsEntry} />
              </Box>
            </Grow>
          ))}
        </TransitionGroup>
      )}
      {unifiedNewsEntries.length > 0 && !unifiedNewsEntriesError && (
        <div className="text-center top-margin">
          <Button
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          >
            {t('common:back_to_top')}
          </Button>
          {displayShowMoreButton && (
            <LoadingButton variant="contained" color="primary" loading={unifiedNewsEntriesLoading} onClick={handleShowMoreClick}>
              {t('common:show_more')}
            </LoadingButton>
          )}
        </div>
      )}
      {!unifiedNewsEntriesLoading && unifiedNewsEntriesError && <div className="text-center top-margin">{t('common:general_error_message')}</div>}
    </div>
  )
}

export default NewsList
