import { t } from 'i18next'
import { FC, ReactNode, useState } from 'react'

import { Typography } from '@mui/material'

import { Screenshot } from '../../../../../api/core'
import { AnalystOverview } from '../../../../../components/AnalystOverview/AnalystOverview'
import GalleryCarousel, { GalleryCarouselImageListingType } from '../../../../../components/GalleryCarousel/GalleryCarousel'
import { HtmlContentParser } from '../../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { MaskedText } from '../../../../../components/MaskedText/MaskedText'
import { ReviewContent } from '../../../../../components/Review/Review'
import { getRandomIntBetween } from '../../../../../helpers/random'
import languageService from '../../../../../services/LanguageService'
import { LockedFeatureId } from '../../../../../types/LockedFeature'
import { useLiveEventsTrackerAccessCheck } from '../../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../../account/hooks/userHooks'
import { Game } from '../../../../game'
import { useIsGameOpenForEveryone } from '../../../../settings'
import { Features } from '../../../../update-impacts'
import { useNewsCardFeatures, useNewsCardScreenshots } from '../../../hooks/unifiedNewsEntryHooks'
import newsEntriesService from '../../../services/NewsEntriesService'
import { NewsEntryType, UnifiedNewsEntry } from '../../../types/UnifiedNewsEntry'
import { NewsCardContentAnalystContentSection } from './NewsCardContentAnalystContentSection/NewsCardContentAnalystContentSection'
import { NewsCardContentEditorialSection } from './NewsCardContentEditorialSection/NewsCardContentEditorialSection'
import { NewsCardContentFeaturesSection } from './NewsCardContentFeaturesSection/NewsCardContentFeaturesSection'
import { NewsCardContentReleaseNotesSection } from './NewsCardContentReleaseNotesSection/NewsCardContentReleaseNotesSection'
import { NewsCardContentRevenueAndDownloadsSection } from './NewsCardContentRevenueAndDownloadsSection/NewsCardContentRevenueAndDownloadsSection'
import { NewsCardContentScreenshotsSection } from './NewsCardContentScreenshotsSection/NewsCardContentScreenshotsSection'
import { NewsCardContentSofLaunchMarketsSection } from './NewsCardContentSofLaunchMarketsSection/NewsCardContentSofLaunchMarketsSection'
import { NewsCardContentUpgradePlanSectionSection } from './NewsCardContentUpgradePlanSectionSection/NewsCardContentUpgradePlanSectionSection'

type NewsCardContentSectionsProps = {
  newsEntry: UnifiedNewsEntry
  hideGameDetails?: boolean
  hasNewsFeedLimitedAccess?: boolean
  limitedFunctionalityBannerType?: LockedFeatureId
  children?: ReactNode
}

export const NewsCardContentSections: FC<NewsCardContentSectionsProps> = ({
  newsEntry,
  hideGameDetails,
  hasNewsFeedLimitedAccess,
  limitedFunctionalityBannerType,
  children,
}) => {
  const currentUserLanguage = useCurrentUserLanguage()
  const entryType = newsEntry.feedEntry.type
  const isEditorialSection = entryType === NewsEntryType.Editorial
  const softLaunchMarkets = newsEntriesService.getSoftLaunchMarketsFromNewsEntry(newsEntry.feedEntry)
  const game = { id: newsEntry.feedEntry.entryData.gameId } as Game
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const [changedFeatures, updatedFeatures] = useNewsCardFeatures({ newsEntry })
  const { gameUpdateScreenshots, versionScreenshots, liveEventScreenshots } = useNewsCardScreenshots({ newsEntry: newsEntry })
  const [imageListingType, setImageListingType] = useState<GalleryCarouselImageListingType>('carousel')
  //const similarGames: NewsEntrySimilarGame[] = newsEntry.feedEntry.entryData.similarGames || []

  return (
    <>
      {isEditorialSection && <NewsCardContentEditorialSection newsEntry={newsEntry} />}

      {/* REVENUE AND DOWNLOADS */}
      {(newsEntry.dhistory || newsEntry.history || newsEntry.revenueDownloadsData) && (
        <NewsCardContentRevenueAndDownloadsSection newsEntry={newsEntry} entryType={entryType} />
      )}

      {/* ANALYST OVERVIEW */}
      {newsEntry.review && !newsEntry.comment && entryType !== NewsEntryType.GpsUpdate && newsEntry.review?.analyst && (
        <NewsCardContentAnalystContentSection analyst={newsEntry.review?.analyst} newsEntry={newsEntry} title={t('reviews:label_analyst_overview')}>
          <AnalystOverview review={newsEntry.review?.gameReview} language={currentUserLanguage} />
        </NewsCardContentAnalystContentSection>
      )}

      {/* ANALYST COMMENT */}
      {newsEntry.comment && entryType === NewsEntryType.GpsUpdate && newsEntry.comment?.analyst && (
        <NewsCardContentAnalystContentSection analyst={newsEntry.comment.analyst} newsEntry={newsEntry} title={t('reviews:label_analyst_comment')}>
          <ReviewContent
            contentHtml={
              newsEntry.comment.analysisComment.content?.comment?.[currentUserLanguage] ||
              newsEntry.comment.analysisComment.content?.comment?.[languageService.defaultLanguage]
            }
          />
        </NewsCardContentAnalystContentSection>
      )}

      {/* LIVE EVENT DESCRIPTION or LIVE EVENT ANALYST NOTE*/}
      {(entryType === NewsEntryType.LiveEventNewEventType || entryType === NewsEntryType.LiveEventAnalystNotes) &&
        newsEntry.feedEntry.entryData?.comment &&
        newsEntry.feedEntry.entryData.analyst && (
          <NewsCardContentAnalystContentSection analyst={newsEntry.feedEntry.entryData.analyst} newsEntry={newsEntry} title={t('common:description')}>
            <LiveEventAnalystContent newsEntry={newsEntry} />
          </NewsCardContentAnalystContentSection>
        )}

      {/* SOFT LAUNCH */}
      {softLaunchMarkets.length > 0 && <NewsCardContentSofLaunchMarketsSection softLaunchMarkets={softLaunchMarkets} />}

      {/* RELEASE NOTES */}
      {!hideGameDetails && newsEntry.versionInfo && <NewsCardContentReleaseNotesSection versionInfo={newsEntry.versionInfo} />}

      {/* CHANGED FEATURES */}
      {changedFeatures.length > 0 && (
        <NewsCardContentFeaturesSection title={t('newsfeed:changes_in_feature_set_topic')} locked={hasNewsFeedLimitedAccess}>
          <Features.Changed.List features={changedFeatures} />
        </NewsCardContentFeaturesSection>
      )}

      {/* UPDATED FEATURES */}
      {updatedFeatures.length > 0 && (
        <NewsCardContentFeaturesSection title={t('common:updated_features')} locked={hasNewsFeedLimitedAccess && !isGameOpenForEveryone}>
          <Features.Updated.List features={updatedFeatures} />
        </NewsCardContentFeaturesSection>
      )}

      {/* GAME UPDATE SCREENSHOTS */}
      {!hideGameDetails && gameUpdateScreenshots.length > 0 && (
        <NewsCardContentScreenshotsSection title={t('newsfeed:label_screenshots')}>
          <GalleryCarousel
            screenshots={gameUpdateScreenshots as Screenshot[]}
            imageListingType={imageListingType}
            hasNewsFeedLimitedAccess={hasNewsFeedLimitedAccess}
            onImageListingTypeChange={(newType) => setImageListingType(newType)}
          />
        </NewsCardContentScreenshotsSection>
      )}

      {/* GAME VERSION SCREENSHOTS */}
      {!hideGameDetails && versionScreenshots.length > 0 && (
        <NewsCardContentScreenshotsSection title={t('newsfeed:label_screenshots')}>
          <GalleryCarousel
            screenshots={versionScreenshots}
            imageListingType={imageListingType}
            hasNewsFeedLimitedAccess={hasNewsFeedLimitedAccess}
            onImageListingTypeChange={(newType) => setImageListingType(newType)}
          />
        </NewsCardContentScreenshotsSection>
      )}

      {/* LIVE EVENT SCREENSHOTS */}
      {liveEventScreenshots.length > 0 && (
        <NewsCardContentScreenshotsSection title={t('newsfeed:label_live_event_screenshots')}>
          <GalleryCarousel
            screenshots={liveEventScreenshots}
            imageListingType={imageListingType}
            hasNewsFeedLimitedAccess={hasNewsFeedLimitedAccess}
            liveEventModalLinkProps={{
              trackedGameId: newsEntry.feedEntry.entryData.gameId,
              eventId: newsEntry.feedEntry.entryData.eventId,
              eventTypeId: newsEntry.feedEntry.entryData.typeId,
            }}
            onImageListingTypeChange={(newType) => setImageListingType(newType)}
          />
        </NewsCardContentScreenshotsSection>
      )}

      {/* LIMITED FUNCTIONALITY BANNER */}
      {limitedFunctionalityBannerType && <NewsCardContentUpgradePlanSectionSection lockedFeatureId={limitedFunctionalityBannerType} />}
    </>
  )
}

export type NewsCardContentSectionProps = {
  newsEntry: UnifiedNewsEntry
}

type LiveEventAnalystContentProps = {
  newsEntry: UnifiedNewsEntry
}
export const LiveEventAnalystContent: FC<LiveEventAnalystContentProps> = ({ newsEntry }) => {
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const currentUserLanguage = useCurrentUserLanguage()

  return hasAccessToLiveEventsTracker ? (
    <HtmlContentParser
      rawHtml={
        newsEntry.feedEntry.entryData.comment?.content.comment?.[currentUserLanguage] ||
        newsEntry.feedEntry.entryData.comment?.content.comment?.[languageService.defaultLanguage]
      }
      parserOptions={liveEventReviewModalParserOptions(newsEntry.feedEntry.gameId)}
    />
  ) : (
    <>
      <Typography variant="h3">
        <MaskedText length={getRandomIntBetween(45, 80)} />
      </Typography>
      <Typography variant="body1">
        <MaskedText length={getRandomIntBetween(90, 120)} />
        <MaskedText length={getRandomIntBetween(15, 45)} />
        <MaskedText length={getRandomIntBetween(50, 110)} />
      </Typography>
    </>
  )
}
