import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Keyword,
  Tag,
  useGetAnalysisFeatureChoicesQuery,
  useGetAnalysisNotesQuery,
  useGetCategoriesMapQuery,
  useGetExplorerFeaturesForConventionalSubgenresQuery,
  useGetKeywordListQuery,
  useGetScreenshotCountsByGamesQuery,
} from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { AllFeatureCategoriesValue } from '../../feature/components/FeatureCategorySelector/FeatureCategorySelector'
import { isFeatureChoice, isKeyword } from '../../feature/helpers/helpers'
import { useFeatureTags } from '../../feature/hooks/useFeatureTags'
import { FeatureAndKeywordSearchResult, InactiveFeatureChoice } from '../../feature/types/types'
import { useConventionalSubgenreIdsBasedOnGenre } from '../../game/hooks/gameHooks'
import { Game } from '../../game/types/Game'
import { ExpandedTagGroup } from '../../implementation-examples'
import { useCurrentMarket } from '../../markets'
import { useCurrentStore } from '../../store'
import { GameFeatureTableRow, PowerscoreBreakdownCategory, PowerscoreItem } from '../types/types'

type UsePowerscoreBreakdownHookParams = {
  analysisId: string
}

const publisherFeatureLegacyId = 174
const brandTag = '57bed2f5bc85fa005b70baba'
const genreTag = '57bed2f5bc85fa005b70baab'

/**
 * Collects data for game powerscore breakdown in categories: Publisher, Brand, Genre and Feature based on given game analysis id
 */
export const usePowerscoreBreakdown = ({ analysisId }: UsePowerscoreBreakdownHookParams) => {
  const { t } = useTranslation()
  const { currentMarketIso } = useCurrentMarket()
  const { currentStoreId } = useCurrentStore()
  const { data: featureChoices } = useGetAnalysisFeatureChoicesQuery(
    { analysisId, marketIso: currentMarketIso, storeId: currentStoreId as string },
    { skip: !analysisId || !currentStoreId }
  )

  const initialBreakdown: { [breakdownItem in PowerscoreBreakdownCategory]: PowerscoreItem } = useMemo(() => {
    const breakdown = Object.values(PowerscoreBreakdownCategory).reduce((acc, item) => {
      acc[item] = {
        name: t(`overview:powerscore_breakdown_${item}_score`),
        description: t(`overview:powerscore_breakdown_${item}_score_description`),
        value: 0,
      }

      return acc
    }, {} as { [breakdownItem in PowerscoreBreakdownCategory]: PowerscoreItem })

    breakdown[PowerscoreBreakdownCategory.Total] = {
      name: t('common:game_power_score'),
      description: t('overview:powerscore_breakdown_powerscore_description'),
      value: 0,
    }

    return breakdown
  }, [t])

  const breakdown = useMemo(() => {
    return (
      featureChoices?.reduce((acc, featureChoice) => {
        if (featureChoice.featureLegacyId === publisherFeatureLegacyId) {
          acc[PowerscoreBreakdownCategory.Publisher].value += featureChoice.gpsEffect
        } else if (featureChoice.tags?.includes(brandTag)) {
          acc[PowerscoreBreakdownCategory.Brand].value += featureChoice.gpsEffect
        } else if (featureChoice.tags?.includes(genreTag)) {
          acc[PowerscoreBreakdownCategory.Genre].value += featureChoice.gpsEffect
        } else {
          acc[PowerscoreBreakdownCategory.Feature].value += featureChoice.gpsEffect
        }

        acc[PowerscoreBreakdownCategory.Total].value += featureChoice.gpsEffect

        return acc
      }, initialBreakdown) || initialBreakdown
    )
  }, [featureChoices, initialBreakdown])

  return breakdown
}

type useGameFeatureRowsHookParams = {
  marketIso: string
  storeId: string | null
  analysisId: string
  game: Game
}

/**
 * Retrieves and maps game feature rows based on game, market, store and game analysis
 */
export const useGameFeatureRows = ({ marketIso, storeId, analysisId, game }: useGameFeatureRowsHookParams) => {
  // resolve subgenre ids for game
  const conventionalSubgenreIdsBasedOnGameGenre = useConventionalSubgenreIdsBasedOnGenre({
    conventionalCategoryId: game.conventionalCategoryId,
    conventionalGenreId: game.conventionalGenreId,
  })

  // retrieve notes for the feature rows
  const { data: analysisNotes } = useGetAnalysisNotesQuery({ gameId: game.id })

  const { data: featureTagGroups } = useFeatureTags()

  const { data: gameScreenshotCount } = useGetScreenshotCountsByGamesQuery({ gameIds: [game.id] })
  const screenshotByFeatureLegacyIds = useMemo(() => (gameScreenshotCount ? gameScreenshotCount[game.id] : {}), [game.id, gameScreenshotCount])
  // query top features by game subgenre ids
  const {
    data: topFeatures,
    isLoading: isTopFeaturesLoading,
    isFetching: isTopFeaturesFetching,
  } = useGetExplorerFeaturesForConventionalSubgenresQuery(
    { marketIso, conventionalSubgenreIds: conventionalSubgenreIdsBasedOnGameGenre, selectedGameId: game.id },
    { skip: !conventionalSubgenreIdsBasedOnGameGenre }
  )

  // query features for given game analysis
  const {
    data: featureChoices,
    isLoading: isFeatureChoicesLoading,
    isFetching: isFeatureChoicesFetching,
  } = useGetAnalysisFeatureChoicesQuery({ analysisId, marketIso, storeId: storeId as string }, { skip: !analysisId || !storeId })

  const { data: featureCategories, isLoading: isFeatuerGategoriesLoading, isFetching: isFeatureCategoriesFetching } = useGetCategoriesMapQuery({ marketIso })

  const isLoading =
    isTopFeaturesLoading ||
    isTopFeaturesFetching ||
    isFeatureChoicesLoading ||
    isFeatureChoicesFetching ||
    isFeatuerGategoriesLoading ||
    isFeatureCategoriesFetching

  const gpsEffectByCategory = useMemo(() => {
    return (
      featureChoices?.reduce((acc, featureChoice) => {
        if (!acc[featureChoice.categoryId]) {
          acc[featureChoice.categoryId] = Math.max(featureChoice.gpsEffect, 0) || 0
        } else {
          acc[featureChoice.categoryId] += Math.max(featureChoice.gpsEffect, 0) || 0
        }

        return acc
      }, {} as { [categoryId: string]: number }) || {}
    )
  }, [featureChoices])

  const mappedRows: GameFeatureTableRow[] = useMemo(() => {
    return (
      featureChoices?.map((featureChoice) => {
        const category = featureCategories?.[featureChoice.categoryId]
        const tagGroupsForTags = new Set<ExpandedTagGroup>()

        if (featureTagGroups && featureChoice.tags) {
          featureChoice.tags.forEach((tagId) => {
            featureTagGroups.forEach((tagGroup) => {
              if (tagGroup.tags.map((tag) => tag.id).includes(tagId)) {
                tagGroupsForTags.add(tagGroup)
              }
            })
          })
        }

        return {
          featureId: featureChoice.featureId,
          featureName: featureChoice.featureLabel,
          featureLegacyId: featureChoice.featureLegacyId,
          screenshotCount: screenshotByFeatureLegacyIds[featureChoice.featureLegacyId] || 0,
          choiceLegacyId: featureChoice.choiceLegacyId,
          choiceName: featureChoice.choiceLabel,
          originalChoiceName: featureChoice.originalChoiceLabel,
          gpsEffect: featureChoice.gpsEffect,
          categoryId: featureChoice.categoryId,
          categoryName: category?.name || '',
          categoryIcon: category?.icon.url || '',
          categoryGpsEffect: gpsEffectByCategory[featureChoice.categoryId] || 0,
          featureGroupName: Array.from(tagGroupsForTags)
            .map((tagGroup) => tagGroup.name)
            .sort()
            .join('/'),
          popularityOverall: topFeatures?.find((topFeature) => topFeature.featureId === featureChoice.featureId)?.popularity.Overall || 0,
          popularityTop20Percent: topFeatures?.find((topFeature) => topFeature.featureId === featureChoice.featureId)?.popularity['20Percent'] || 0,
          note: analysisNotes && analysisNotes[featureChoice.featureLegacyId] && analysisNotes[featureChoice.featureLegacyId].notes,
        }
      }) || []
    )
  }, [featureChoices, featureCategories, featureTagGroups, screenshotByFeatureLegacyIds, gpsEffectByCategory, topFeatures, analysisNotes])

  return { rows: mappedRows, isLoading }
}

type useFilteredGameFeatureRowsHookParams = {
  rows: GameFeatureTableRow[]
  includeGameFeaturesOnly?: boolean
  includeGpsEffectsOnly?: boolean
  categoryId: string
  featureSearchValue: FeatureAndKeywordSearchResult
  featureTags: Tag[]
  keywords: Keyword[]
}

/**
 * Filters game feature rows according to given filters
 */
export const useFilteredGameFeatureRows = ({
  rows,
  includeGameFeaturesOnly,
  categoryId,
  featureSearchValue,
  featureTags,
  keywords,
}: useFilteredGameFeatureRowsHookParams) => {
  return useMemo(() => {
    return (
      rows
        .filter((featureChoice) => {
          // remove features not found in the game if includeGameFeaturesOnly flag is on
          const choice = featureChoice.originalChoiceName?.trim().toLowerCase()
          return !includeGameFeaturesOnly || !Object.values(InactiveFeatureChoice).includes(choice as InactiveFeatureChoice)
        })
        .filter((featureChoice) => {
          // filter by category
          return categoryId !== AllFeatureCategoriesValue ? featureChoice.categoryId === categoryId : true
        })
        .filter((featureChoice) => {
          // filter by selected feature, keyword or string
          if (isKeyword(featureSearchValue)) {
            return featureSearchValue.linkedIds.includes(featureChoice.featureLegacyId)
          } else if (isFeatureChoice(featureSearchValue)) {
            return featureSearchValue.featureLegacyId === featureChoice.featureLegacyId
          } else if (typeof featureSearchValue === 'string') {
            const keywordMatch = keywords
              .filter((keyword) => keyword.name.toLocaleLowerCase().includes(featureSearchValue.toLocaleLowerCase()))
              .some((keyword) => keyword.linkedIds.includes(featureChoice.featureLegacyId))

            return keywordMatch || featureChoice.featureName.toLowerCase().includes(featureSearchValue.toLowerCase())
          }

          return true
        })
        .filter((featureChoice) => {
          // filter by feature tags, if feature search value is provided this filter is skipped
          return featureSearchValue
            ? true
            : featureTags.reduce((acc, featureTag) => {
                return acc || !!featureSearchValue || featureTag.targets.includes(featureChoice.featureLegacyId)
              }, false as boolean)
        }) || []
    )
  }, [categoryId, featureSearchValue, featureTags, includeGameFeaturesOnly, keywords, rows])
}

/**
 * Constructs keywords as feature search options. Feature tags are converted to keywords as well.
 */
export const useGameFeatureKeywords = () => {
  const userLanguage = useCurrentUserLanguage()
  const { data: featureKeywords } = useGetKeywordListQuery({ type: 'feature', userLanguage })
  const { data: featureTags } = useFeatureTags()

  const allFeatureTags = useMemo(() => featureTags?.flatMap((featureTag) => featureTag.tags) || [], [featureTags])

  const keywords = useMemo(() => {
    const featureTagsAsKeywords = allFeatureTags
      .filter((featureTag) => featureTag.active)
      .map(
        (featureTag) =>
          ({
            id: featureTag.id,
            name: featureTag.name,
            linkedIds: featureTag.targets,
            priority: 0,
          } as Keyword)
      )

    return [...(featureKeywords || []), ...featureTagsAsKeywords]
  }, [allFeatureTags, featureKeywords])

  return keywords
}
