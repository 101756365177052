import querystring from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BookType } from 'xlsx'

import { Button, Card, CircularProgress, Divider, Grid, TableContainer } from '@mui/material'
import { Box } from '@mui/system'

import { Game } from '../..'
import { useGetGameCompetitorsQuery } from '../../../../api/core'
import { AnalystOverviewDialog } from '../../../../components/AnalystOverviewDialog/AnalystOverviewDialog'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import GameNotAnalyzedCard from '../../../../components/GameNotAnalyzedCard/GameNotAnalyzedCard'
import { LockedDataIndicator } from '../../../../components/LockedDataIndicator/LockedDataIndicator'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import NotAvailableIcon from '../../../../components/NotAvailableIcon/NotAvailableIcon'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { useExportDataGameCompetitors } from '../../../../hooks/exportDataHooks'
import { useGetCategoriesArray } from '../../../../hooks/useGetCategoriesArray'
import analyticsService from '../../../../services/AnalyticsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { TableSortValues } from '../../../../types/TableSortValues'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { Analysis } from '../../../analysis/types/Analysis'
import { useCompareMultiGames } from '../../../compare-games/hooks/useCompareMultiGames'
import { demographicsCellProps, demographicsHeaderCellProps } from '../../../demographics/components/DemographicsTableBars/DemographicsTableBars'
import { DemographicsColor } from '../../../demographics/types/DemographicsColor'
import { DemographicsDataKey } from '../../../demographics/types/DemographicsDataKey'
import { generateExport } from '../../../export-data/util/workbook'
import { mapSegmentConfigurationToUrlObject } from '../../../market-explorer/hooks/useMarketExplorerSearchParams'
import { MarketExplorerSegmentConfiguration } from '../../../market-explorer/types/MarketExplorerSegmentConfiguration'
import { useCurrentMarket } from '../../../markets'
import { useIsGameOpenForEveryone } from '../../../settings'
import { GameCardContent } from '../GameCard/GameCard'
import GameDemographicsTableDataBar from '../GameDemographicsTableDataBar/GameDemographicsTableDataBar'
import { GamePowerscore } from '../GamePowerscore/GamePowerscore'
import GameSkillThinkingMeter from '../GameSkillThinkingMeter/GameSkillThinkingMeter'
import styles from './GameCompetitors.module.scss'

interface Props {
  game: Game
  analysis?: Analysis
}

const maxRankValue = 1000
const GameCompetitors: React.FC<Props> = ({ game, analysis }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Competitors', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis?.id, game.id, game.resolvedName])

  const { t } = useTranslation()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<Game | null>(null)
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const featureUnlocked = !useRoleCheck(RoleEnum.hide_subgenres) || isGameOpenForEveryone
  const demographicsUnlocked = useRoleCheck(RoleEnum.demographics)
  const displayDemographics = isGameOpenForEveryone || demographicsUnlocked
  const gameIsAnalyzed = useMemo(() => {
    return game.isAnalyzedForMarket(mainMarketIso)
  }, [game, mainMarketIso])
  const { data: competitorGames, isLoading: isLoadingCompetitors } = useGetGameCompetitorsQuery(
    { gameId: game.id, marketIso: mainMarketIso, userLanguage },
    { skip: !gameIsAnalyzed }
  )

  const topCompetitorGames = useMemo(() => {
    if (!competitorGames) {
      return undefined
    }

    let filteredGames = [...competitorGames]
    if (filteredGames.length > 20) {
      filteredGames.length = 20
    }

    return [game, ...filteredGames]
  }, [game, competitorGames])

  const [loadCompetitorsForDataExport, setLoadCompetitorsForDataExport] = useState(false)

  const { data: multiGamesCompare } = useCompareMultiGames({
    gameIds: topCompetitorGames?.map((game) => game.id) || [],
    marketIsos: topCompetitorGames?.map((game) => mainMarketIso) || [],
  })

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const { categories } = useGetCategoriesArray('us')

  const { exportRows } = useExportDataGameCompetitors(multiGamesCompare, categories, topCompetitorGames, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length || !multiGamesCompare || !categories || !loadCompetitorsForDataExport || !topCompetitorGames) return
    setLoadCompetitorsForDataExport(false)
    setIsExporting(false)

    const fileName = 'game-' + game.appId + '-competitors'

    generateExport(exportFormat, exportRows, 'Game Competitors', fileName)
  }, [t, game.appId, isExporting, exportRows, exportFormat, multiGamesCompare, categories, loadCompetitorsForDataExport, topCompetitorGames])

  const openCompetitorsDataToMarketExplorer = () => {
    // construct segment configurations for market explorer and serialize it to url query string
    const segmentWithCurrentGame: MarketExplorerSegmentConfiguration = {
      visible: true,
      marketIso: mainMarketIso,
      filters: {
        ranks: {},
        demographics: {},
        motivations: {},
        archetypes: {},
      },
      gameIds: [game.id],
    }

    const segmentWithCompetitorGames: MarketExplorerSegmentConfiguration = {
      visible: true,
      marketIso: mainMarketIso,
      filters: {
        ranks: {},
        demographics: {},
        motivations: {},
        archetypes: {},
      },
      gameIds: topCompetitorGames?.filter((competitorGame) => competitorGame.id !== game.id).map((game) => game.id),
    }

    const segments = game.isPublic() ? [segmentWithCurrentGame, segmentWithCompetitorGames] : [segmentWithCompetitorGames]

    const segmentParams = segments.reduce((acc, segment, index) => {
      const mappedSegment = mapSegmentConfigurationToUrlObject(segment, index)

      return {
        ...mappedSegment,
        ...acc,
      }
    }, {})

    navigate({ pathname: '/market-explorer', search: querystring.stringify(segmentParams, { arrayFormat: 'comma' }) })
  }

  const getRankValue = useCallback(
    (game: Game, rank: number, formatted: boolean = false) => {
      if (!game.isPublic()) {
        return <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} />
      } else if (!rank || rank > maxRankValue) {
        return formatted ? t('common:number_plus', { number: maxRankValue }) : maxRankValue
      } else {
        return rank
      }
    },
    [t]
  )

  const getFormattedDemographicsValue = useCallback(
    (game: Game, demographicsKey: DemographicsDataKey) => {
      const value = game.getDemographicsValue(mainMarketIso, demographicsKey)
      return value !== 0 ? `${value}%` : <NotAvailableIcon fontSize="18px" />
    },
    [mainMarketIso]
  )

  const sortAccessor = useCallback(
    (value: any, row: Game, sortOrder?: SortOrder, hasMarketIso?: boolean) => {
      if (row.id === game.id) {
        return TableSortValues.AlwaysFirst
      } else if (!hasMarketIso || value === undefined || value === 0) {
        return sortOrder === 'desc' && Number.MIN_SAFE_INTEGER
      } else {
        return value
      }
    },
    [game.id]
  )

  const tableColumns: GRTableColumn<Game, undefined>[] = [
    {
      columns: [
        {
          labelAccessor: t('common:competitors'),
          accessor: ({ row: game }) => (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <GameCardContent game={game} rank={game.competitorOrder} variant="table" />
              </Grid>
              <Grid item>{game.reviewId && game.reviewPublished && <ReviewIndicator onClick={() => setAnalystOverviewDialogGame(game)} />}</Grid>
            </Grid>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) => sortAccessor(game.competitorOrder, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          cellProps: { align: 'left' },
          headerCellProps: { sx: { minWidth: 250 } },
          sortOrder: SortOrder.ASC,
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    {
      labelAccessor: t('common:feature_indicators_text'),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('common:tooltip_gps_description')}>{t('common:game_power_score')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>{game.gpsPerMarket[mainMarketIso] ? <GamePowerscore value={game.gpsPerMarket[mainMarketIso]} /> : <NotAvailableIcon fontSize="18px" />}</>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.gpsPerMarket[mainMarketIso], game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
        },
        {
          labelAccessor: () => <GRTooltip content={t('common:skill_thinking_description')}>{t('common:skill_thinking')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {game.gpsPerMarket[mainMarketIso] ? (
                <GameSkillThinkingMeter sensomotoric={game.sensomotoric} cognitive={game.cognitive}></GameSkillThinkingMeter>
              ) : (
                <NotAvailableIcon fontSize="18px" />
              )}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) => sortAccessor(game.sensomotoric, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
        },
      ],
    },
    {
      labelAccessor: t('common:sustained_ranks_text'),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('common:sustained_grossing_rank_description')}>{t('common:grossing_rank')}</GRTooltip>,
          accessor: ({ row: game }) => {
            return <strong>{getRankValue(game, game.sranks[mainMarketIso], true)}</strong>
          },
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(getRankValue(game, game.sranks[mainMarketIso]), game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          defaultSortOrder: SortOrder.ASC,
          headerCellProps: { sx: { minWidth: 160 } },
        },
        {
          labelAccessor: () => <GRTooltip content={t('common:sustained_download_rank_description')}>{t('common:download_rank')}</GRTooltip>,
          accessor: ({ row: game }) => <strong>{getRankValue(game, game.sdranks[mainMarketIso], true)}</strong>,
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(getRankValue(game, game.sdranks[mainMarketIso]), game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          defaultSortOrder: SortOrder.ASC,
          headerCellProps: { sx: { minWidth: 160 } },
        },
      ],
    },
    {
      labelAccessor: t('common:gender'),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('common:male_description')}>{t('common:male')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {displayDemographics && (
                <>
                  <strong>{getFormattedDemographicsValue(game, DemographicsDataKey.Male)}</strong>
                  <GameDemographicsTableDataBar
                    game={game}
                    marketIso={mainMarketIso}
                    dataKeys={[DemographicsDataKey.Male, DemographicsDataKey.Female]}
                    colors={[DemographicsColor.Male, DemographicsColor.Female]}
                  />
                </>
              )}

              {!displayDemographics && <LockedDataIndicator />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.getDemographicsValue(mainMarketIso, DemographicsDataKey.Male) || 0, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          headerCellProps: demographicsHeaderCellProps,
          cellProps: demographicsCellProps,
        },
        {
          labelAccessor: () => <GRTooltip content={t('common:female_description')}>{t('common:female')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {displayDemographics && <strong>{getFormattedDemographicsValue(game, DemographicsDataKey.Female)}</strong>}

              {!displayDemographics && <LockedDataIndicator />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.getDemographicsValue(mainMarketIso, DemographicsDataKey.Female) || 0, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          headerCellProps: demographicsHeaderCellProps,
        },
      ],
    },
    {
      labelAccessor: t('common:age'),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('common:age16_24_description')}>{t('common:age16_24_short')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {displayDemographics && (
                <>
                  <strong>{getFormattedDemographicsValue(game, DemographicsDataKey.Age16_24)}</strong>
                  <GameDemographicsTableDataBar
                    game={game}
                    marketIso={mainMarketIso}
                    dataKeys={[DemographicsDataKey.Age16_24, DemographicsDataKey.Age25_44, DemographicsDataKey.Age45_plus]}
                    colors={[DemographicsColor.Age16_24, DemographicsColor.Age25_44, DemographicsColor.Age45_plus]}
                  />
                </>
              )}

              {!displayDemographics && <LockedDataIndicator />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.getDemographicsValue(mainMarketIso, DemographicsDataKey.Age16_24) || 0, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          headerCellProps: demographicsHeaderCellProps,
          cellProps: demographicsCellProps,
        },
        {
          labelAccessor: () => <GRTooltip content={t('common:age25_44_description')}>{t('common:age25_44_short')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {displayDemographics && <strong>{getFormattedDemographicsValue(game, DemographicsDataKey.Age25_44)}</strong>}
              {!displayDemographics && <LockedDataIndicator />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.getDemographicsValue(mainMarketIso, DemographicsDataKey.Age25_44) || 0, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          headerCellProps: demographicsHeaderCellProps,
        },
        {
          labelAccessor: () => <GRTooltip content={t('common:age45_plus_description')}>{t('common:age45_plus_short')}</GRTooltip>,
          accessor: ({ row: game }) => (
            <>
              {displayDemographics && <strong>{getFormattedDemographicsValue(game, DemographicsDataKey.Age45_plus)}</strong>}

              {!displayDemographics && <LockedDataIndicator />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: game, col: { sortOrder } }) =>
            sortAccessor(game.getDemographicsValue(mainMarketIso, DemographicsDataKey.Age45_plus) || 0, game, sortOrder, !!game.gpsPerMarket[mainMarketIso]),
          headerCellProps: demographicsHeaderCellProps,
        },
      ],
    },
  ]

  const [columns, setColumns] = useState<GRTableColumn<Game, undefined>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<Game, undefined>[]) => {
    setColumns(updatedColumns)
  }, [])

  const handleDataExport = useCallback((format: BookType) => {
    setLoadCompetitorsForDataExport(true)
    setExportFormat(format)
    setIsExporting(true)
  }, [])

  return (
    <div className="GameCompetitors">
      <>
        {!featureUnlocked && <LockedFeature.Card lockedFeatureId={LockedFeatureId.GameCompetitors} />}

        {!gameIsAnalyzed && featureUnlocked && <GameNotAnalyzedCard game={game} />}

        {gameIsAnalyzed && featureUnlocked && (
          <>
            <Grid container spacing={1}>
              <Grid item xs>
                {t('overview:games_100_list_share_same_genre')}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={openCompetitorsDataToMarketExplorer}>
                  {t('overview:show_competitors_market_explorer_button')}
                </Button>
              </Grid>
              <Grid item>
                <ExportDataButton
                  accessRoles={[RoleEnum.competitors_csv]}
                  onChooseFormat={handleDataExport}
                  loading={isExporting}
                  analyticsEventOrigin="game competitor"
                />
              </Grid>
            </Grid>

            <Box my={2}>
              <Divider />
            </Box>

            {isLoadingCompetitors && (
              <div className="text-center top-margin">
                <CircularProgress color="primary" />
              </div>
            )}

            {!isLoadingCompetitors && topCompetitorGames && (
              <TableContainer component={Card} ref={containerRef} className={styles.container}>
                <GRTable
                  rows={topCompetitorGames}
                  columns={columns}
                  striped
                  hoverable
                  onColumnsUpdated={handleColumnsUpdate}
                  rowIdKey="id"
                  noRowsLabel={t('market-explorer:no_games_available')}
                  scroller={containerRef}
                />
              </TableContainer>
            )}
            <AnalystOverviewDialog
              reviewId={analystOverviewDialogGame?.reviewId}
              gameName={analystOverviewDialogGame?.resolvedName}
              open={!!analystOverviewDialogGame}
              onClose={() => setAnalystOverviewDialogGame(null)}
            />
          </>
        )}
      </>
    </div>
  )
}

export default GameCompetitors
