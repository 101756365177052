import classNames from 'classnames'
import querystring from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import { ArrowDropDown, CloseRounded, NewReleases } from '@mui/icons-material'
import { Box, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'

import { ConceptTag, Screenshot } from '../../api/core'
import { useAnalystReviewsAndCommentsAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { isEnrichedFeature } from '../../features/feature/helpers/helpers'
import { Game, GameIcon } from '../../features/game'
import { useGetGameVersionInfo } from '../../features/game/hooks/gameHooks'
import { EnrichedFeature } from '../../features/implementation-examples'
import { screenshotIndexFromScreenshotId } from '../../features/implementation-examples/util/screenshotHelpers'
import { useCurrentMarket } from '../../features/markets'
import { formatDate } from '../../helpers/date'
import languageService from '../../services/LanguageService'
import { VersionInfo } from '../../types/VersionInfo'
import AddToCollectionButton from '../AddToCollectionButton/AddToCollectionButton'
import { GRCarousel } from '../GRCarousel/GRCarousel'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import NotAvailableIcon from '../NotAvailableIcon/NotAvailableIcon'
import ShareUrlButton from '../ShareUrlButton/ShareUrlButton'
import AnalystComment from './AnalystComment'
import './ScreenshotModal.scss'

export type ScreenshotGame = Pick<Game, 'id' | 'resolvedName' | 'artist' | 'icon' | 'icons' | 'appId'>

export type SimpleVersionInfo = Pick<VersionInfo, 'version' | 'versionReleaseDate'> & {
  description?: string
  releaseNotes?: string
}

enum Direction {
  Next = +1,
  Prev = -1,
}

interface ScreenshotModalProps {
  games?: ScreenshotGame[]
  game?: ScreenshotGame
  loading?: boolean
  onClose: () => void
  renderExtra?: (screenshot: Screenshot) => JSX.Element | undefined
  screenshots: Screenshot[]
  title?: string
  versionInfo?: SimpleVersionInfo
  shotId?: string
  classifier?: EnrichedFeature | ConceptTag | undefined
}

/**
 * Use version info from current screenshot, if available.
 *
 * @param versionInfo to rollback to
 * @param screenshot to check first
 */
const parseVersion = (versionInfo: SimpleVersionInfo, screenshot?: Screenshot): SimpleVersionInfo | undefined =>
  screenshot?.gameVersion && screenshot?.versionReleaseDate
    ? {
        version: screenshot.gameVersion,
        versionReleaseDate: screenshot.versionReleaseDate,
        description: versionInfo?.description,
        releaseNotes: versionInfo?.releaseNotes,
      }
    : versionInfo

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * The Screenshot Modal component. Added the arrow key screenshot navigation for convenience.
 *
 * @param renderExtra the bottom part of the modal can be used for specific info (e.g. news card modals)
 *                    instead of rendering the analyst comment
 */
const ScreenshotsModal: React.FC<ScreenshotModalProps> = ({
  games,
  game,
  loading,
  onClose,
  renderExtra,
  screenshots,
  title,
  versionInfo,
  shotId,
  classifier,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()

  const hasAnalystCommentsAccess = useAnalystReviewsAndCommentsAccessCheck()

  const [loadingImage, setLoadingImage] = useState(true)
  const [showReleaseNotes, setShowReleaseNotes] = useState(false)

  const [currentIndex, current] = useMemo(() => {
    const shot = (new URLSearchParams(location.search).get('shot') || shotId) as string
    if (shot === null || shot === undefined) return [0, screenshots[0]]
    let currentIndex = screenshotIndexFromScreenshotId(screenshots, shot)
    if (currentIndex === undefined) {
      currentIndex = 0
    }

    return [currentIndex, screenshots[currentIndex]]
  }, [location.search, loading, screenshots?.length])

  const selectedGame = useMemo(() => {
    if (!games) {
      return game
    }

    return games.find((game) => current.gameId === game.id)
  }, [games, game, current])

  const { data: gameVersionInfo, isLoading: isVersionInfoLoading } = useGetGameVersionInfo(
    selectedGame?.appId,
    currentMarketIso,
    screenshots?.length ? screenshots[currentIndex].gameVersion : '',
    screenshots?.length ? !screenshots[currentIndex].gameVersion : false
  )

  const keyDownHandler = ({ key }: KeyboardEvent) => {
    if (key === 'ArrowLeft' && currentIndex >= 1) {
      jump(Direction.Prev)
    } else if (key === 'ArrowRight' && currentIndex < screenshots.length - 1) {
      jump(Direction.Next)
    }
  }

  const jumpParams = (direction: Direction) => {
    const query = querystring.parse(location.search)
    const index = currentIndex + direction
    if (index < 0 || index > screenshots.length - 1) {
      return query
    }
    const screenshot = screenshots[index]
    const shot = screenshot?.screenshotId || screenshot.id
    return { ...query, shot }
  }

  const jump = (direction: Direction) => {
    const query = jumpParams(direction)
    if (!query.shot) {
      return
    }
    navigate({ search: querystring.stringify(query) }, { replace: true })
    setLoadingImage(true)
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [location.search])

  const [version, hasVersionDetails] = useMemo(() => {
    const version = parseVersion(gameVersionInfo || (versionInfo as SimpleVersionInfo), current)
    const hasVersionDetails = version?.releaseNotes || version?.description || isVersionInfoLoading
    return [version, hasVersionDetails]
  }, [current, versionInfo, gameVersionInfo])

  return (
    <Dialog maxWidth="xl" fullWidth open onClose={onClose} closeAfterTransition className="ScreenshotModal">
      {loading || !selectedGame ? (
        <Box textAlign="center" my={4}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        current && (
          <>
            <DialogTitle>
              <Grid container justifyContent="space-between" className="header" alignItems="flex-end">
                <Grid item sm={4} alignSelf="center">
                  <Link to={`/game/${selectedGame.id}`}>
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      <GameIcon gameName={selectedGame.resolvedName} src={(selectedGame.icon || selectedGame.icons[0]) as string} />
                      <Box className="gameContent">
                        <GRTooltip content={selectedGame.resolvedName}>
                          <Typography variant="h3" className="gameName">
                            {selectedGame.resolvedName}
                          </Typography>
                        </GRTooltip>
                        <Typography variant="body1" className="artist">
                          {selectedGame.artist}
                        </Typography>
                        <Typography variant="subtitle2" className="version">
                          {!version && <NotAvailableIcon tooltipContent={`${t('common:version')}: ${t('common:not_available_shorthand')}`} />}
                          {version?.version && `${t('common:version')} ${version.version}`}
                          {version?.versionReleaseDate && ` - ${formatDate(version.versionReleaseDate, { language: userLanguage })}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                <Grid item xs={4} textAlign="center">
                  {title && (
                    <Typography variant="h6" mb={2}>
                      {classifier && isEnrichedFeature(classifier)
                        ? languageService.getTranslation('features', (classifier as EnrichedFeature).legacyId.toString())
                        : title}
                    </Typography>
                  )}
                  <Typography>
                    {currentIndex + 1}/{screenshots.length}
                  </Typography>
                </Grid>
                <Grid item sm={4} textAlign="right">
                  <IconButton className="closeButton" onClick={onClose}>
                    <CloseRounded />
                  </IconButton>
                  <Box className="actionButtons">
                    <ShareUrlButton />
                    <AddToCollectionButton screenshotId={current?.screenshotId || current.id} game={selectedGame as Game} />
                    {hasVersionDetails && (
                      <Button
                        variant="contained"
                        onClick={() => setShowReleaseNotes(!showReleaseNotes)}
                        color={showReleaseNotes ? 'secondary' : 'primary'}
                        disabled={isVersionInfoLoading || version?.releaseNotes === ''}
                      >
                        {t('common:release_notes')}
                        {isVersionInfoLoading ? <CircularProgress size={16} /> : <ArrowDropDown className={classNames({ upsideDown: showReleaseNotes })} />}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogTitle>
            {hasVersionDetails && (
              <Collapse in={showReleaseNotes} sx={{ overflowY: 'scroll' }}>
                <Box className="releaseNotes" sx={{ display: 'flex', minHeight: '100px' }}>
                  <NewReleases sx={{ mt: 1 }} />
                  <Box>
                    <Typography variant="h6" color="primary">
                      {t('common:release_notes')}
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-line' }} component="pre">
                      {version?.releaseNotes || version?.description}
                    </Typography>
                  </Box>
                </Box>
              </Collapse>
            )}
            <DialogContent dividers sx={{ display: 'flex', padding: 0 }}>
              <Grid container direction="column">
                <Grid item className="images">
                  <GRCarousel
                    previousButtonProps={{
                      component: Link,
                      disabled: currentIndex <= 0,
                      onClick: () => setLoadingImage(true),
                      to: `${location.pathname}?${querystring.stringify(jumpParams(Direction.Prev))}`,
                    }}
                    nextButtonProps={{
                      component: Link,
                      disabled: currentIndex >= screenshots.length - 1,
                      onClick: () => setLoadingImage(true),
                      to: `${location.pathname}?${querystring.stringify(jumpParams(Direction.Next))}`,
                    }}
                  >
                    <img className={classNames('image', { loadingImage })} alt="Screenshot" src={current.url} onLoad={() => setLoadingImage(false)} />
                    {loadingImage && <CircularProgress />}
                  </GRCarousel>
                </Grid>

                <Grid item className="footer">
                  {current.commentId && hasAnalystCommentsAccess && <AnalystComment commentId={current.commentId} />}
                  {renderExtra && <Grid item>{renderExtra && renderExtra(current)}</Grid>}
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )
      )}
    </Dialog>
  )
}

export default ScreenshotsModal
